<template>
  <div class="inner_pages">
    <div class="respective_content">
      <div class="container">
        <div class="cancel_heading">
          <h2>Ready to Part Ways? <span>You're about to cancel your subscription.</span></h2>
          <h4>Cancelling your Thrive Coach account will deactivate your login and remove anything created at the end of
            your current billing cycle. Everything will stop functioning at that time, but your data and settings will
            be saved for 30 days, just in case you choose to re-activate your account during that time.</h4>
        </div>
        <div class="reasoning_list">
          <ul>
            <template v-for="tag in tags" v-bind:key="tag.index">
              <li @click="active_tab = tag.index" :class="{'active' : active_tab === tag.index}">
                <label>{{ tag.label }}</label></li>
            </template>
          </ul>
        </div>
        <div class="reasoning_details">
          <template v-if="active_tab === 0">
            <h3>Please let us know why you are thinking about canceling your Thrive Coach account by selecting an option
              from the reasons above.</h3>
          </template>
          <template v-if="active_tab === 1">
            <h3>Wait! We may have a lower priced membership level that suits you...</h3>
            <ul>
              <li>
                <p>Since we offer a wide range of pricing options and features, there may be a better pricing option for
                  you. With <span>1-Year Annual</span> pricing. you can continue using your Thrive Coach account for as
                  little as <span>$39</span> per month.<br/><br/>
                  We encourage you to check out all pricing options before making your final decision.
                </p>
                <div class="cta_btn" @click="viewPricing($event)">Explore New Pricing Options</div>
              </li>
            </ul>
            <div class="advance_option">
              <h3>Before you click Cancel subscription</h3>
              <p>We would love to know what price would change your mind:</p>
              <div class="comment_wpr">
                <div class="field_wpr" :class="{ 'has-error': commentEmpty }">
                  <textarea name="" v-model="comment" placeholder="Type your comment here.."></textarea>
                </div>
              </div>
              <div class="action_wpr">
                <button type="button" class="btn cancel_btn" @click="displayCancellationPrompt()">Cancel Subscription
                </button>
                <button type="button" class="btn save_btn" @click="toggleChat()">Contact Support</button>
              </div>
            </div>
          </template>
          <template v-if="active_tab === 2">
            <h3>We're here to help &#8212; let's find a solution to the problem you're facing. </h3>
            <ul>
              <li>
                <p><span>Did you know </span> &#8212; you can hop on a live Zoom session with one of our customer
                  success team members every weekday at 3:00 pm eastern time USA? They will walk you through any tech or
                  use questions you have. You can join as often as you like!</p>
                <div class="cta_btn" @click="displayOfficeModel()">Save My Seat</div>
              </li>
              <li>
                <p><span>Want 1-on-1 help from our specialist?</span> &#8212; One of our <span>Customer Happiness Specialists</span>
                  will be happy to guide you through any difficulties you have encountered.</p>
                <div class="cta_btn" @click="bookCall()">Book A Clarity Call</div>
              </li>
            </ul>
            <div class="advance_option">
              <h3>Before you click Cancel subscription</h3>
              <p>We would love to know any specific challenges or obstacles you encountered while using our product:</p>
              <div class="comment_wpr">
                <div class="field_wpr" :class="{ 'has-error': commentEmpty }">
                  <textarea name="" v-model="comment" placeholder="Type your comment here.."></textarea>
                </div>
              </div>
              <div class="action_wpr">
                <button type="button" class="btn cancel_btn" @click="displayCancellationPrompt()">Cancel Subscription
                </button>
                <button type="button" class="btn save_btn" @click="toggleChat()">Contact Support</button>
              </div>
            </div>
          </template>
          <template v-if="active_tab === 3">
            <h3>Wait! It's very possible Thrive Coach does support the fetaure you need.</h3>
            <ul>
              <li>
                <p>Let's double-check and see if <span>Thrive Coach</span> can perform the function you need. Click the
                  button below to start a live chat with one of our <span>Thrive Coach</span> functionality experts.</p>
                <button type="button" class="cta_btn" @click="toggleChat()">Open Live Chat</button>
              </li>
            </ul>
            <div class="advance_option">
              <h3>Before you click Cancel subscription</h3>
              <p>Please Let us know what function or feature is missing from our product:</p>
              <div class="comment_wpr">
                <div class="field_wpr" :class="{ 'has-error': commentEmpty }">
                  <textarea name="" v-model="comment" placeholder="Type your comment here.."></textarea>
                </div>
              </div>
              <div class="action_wpr">
                <button type="button" class="btn cancel_btn" @click="displayCancellationPrompt()">Cancel Subscription
                </button>
                <button type="button" class="btn save_btn" @click="toggleChat()">Contact Support</button>
              </div>
            </div>
          </template>
          <template v-if="active_tab === 4">
            <h3>Do you know about some of the features that make Thrive Coach unique?</h3>
            <ul>
              <li>
                <p>Our amazing Customer Support Team is here to serve you and find solutions for you. Feel free to send them an Email at support@thrivecoach.io, chat by clicking the button on this page, or booking a free clarity call so we can help you through any problems you're facing.</p>
                <div class="cta_btn" @click="bookCall()">Book A Clarity Call</div>
              </li>
            </ul>
            <div class="advance_option">
              <h3>Before you click Cancel subscription</h3>
              <p>Please Let us know &#8212; what other product(s) are you using?</p>
              <div class="comment_wpr">
                <div class="field_wpr" :class="{ 'has-error': commentEmpty }">
                  <textarea name="" v-model="comment" placeholder="Type your comment here.."></textarea>
                </div>
              </div>
              <div class="action_wpr">
                <button type="button" class="btn cancel_btn" @click="displayCancellationPrompt()">Cancel Subscription
                </button>
                <button type="button" class="btn save_btn" @click="toggleChat()">Contact Support</button>
              </div>
            </div>
          </template>
          <template v-if="active_tab === 5">
            <h3>We'd love the chance to walk you through exactly how Thrive Coach can help your business.</h3>
            <ul>
              <li>
                <p><span>Did you know </span> &#8212; you can hop on a live Zoom session with one of our customer
                  success team members every weekday at 3:00 pm eastern time USA? They will walk you through any tech or
                  use questions you have. You can join as often as you like!</p>
                <div class="cta_btn" @click="displayOfficeModel()">Save My Seat</div>
              </li>
              <li>
                <p><span>Want 1-on-1 help from our specialist?</span> &#8212; One of our <span>Customer Happiness Specialists</span>
                  will be happy to guide you through any difficulties you have encountered.</p>
                <div class="cta_btn" @click="bookCall()">Book My Thrive Call</div>
              </li>
            </ul>
            <div class="advance_option">
              <h3>Before you click Cancel subscription</h3>
              <p>Please Let us know &#8212; why you've decided not to use our product.</p>
              <div class="comment_wpr">
                <div class="field_wpr" :class="{ 'has-error': commentEmpty }">
                  <textarea name="" v-model="comment" placeholder="Type your comment here.."></textarea>
                </div>
              </div>
              <div class="action_wpr">
                <button type="button" class="btn cancel_btn" @click="displayCancellationPrompt()">Cancel Subscription
                </button>
                <button type="button" class="btn save_btn" @click="toggleChat()">Contact Support</button>
              </div>
            </div>
          </template>
          <template v-if="active_tab === 6">
            <div class="advance_option m-0">
              <h3>Please tell us about your reason for canceling</h3>
              <p>We will get in touch if we feel there is something we can do about it.</p>
              <div class="comment_wpr">
                <div class="field_wpr" :class="{ 'has-error': commentEmpty }">
                  <textarea name="" v-model="comment" placeholder="Type your comment here.."></textarea>
                </div>
              </div>
              <div class="action_wpr">
                <button type="button" class="btn cancel_btn" @click="displayCancellationPrompt()">Cancel Subscription
                </button>
                <button type="button" class="btn save_btn" @click="toggleChat()">Contact Support</button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="modal secondary prompt" v-if="confirmation_prompt">
      <div class="modal_container">
        <div class="scroll_box">
          <button class="close_btn" @click="confirmation_prompt = false;"><i class="fas fa-times"></i></button>
          <img src="@/assets/images/marketplace.svg" alt="" class="logo_img">
          <h3>Do you really want to delete your account?</h3>
          <h5>Canceling your account will result in the deletion of your account and all related data. By canceling your
            account, you need to acknowledge the following:</h5>
          <ul>
            <li>
              <label for="clause-1" class="checkbox">
                <input type="checkbox" v-model="clause1" id="clause-1" :true-value="1" :false-value="0" hidden>
                <span><i class="fas fa-check"></i></span>
                <p>I understand that my account will be deleted and all the assets I've created will stop
                  functioning.</p>
              </label>
            </li>
            <li>
              <label for="clause-2" class="checkbox">
                <input type="checkbox" v-model="clause2" id="clause-2" :true-value="1" :false-value="0" hidden>
                <span><i class="fas fa-check"></i></span>
                <p>I understand that I can re-activate my account at anytime over the next 30 days and recover my
                  work.</p>
              </label>
            </li>
            <li>
              <label for="clause-3" class="checkbox">
                <input type="checkbox" v-model="clause3" id="clause-3" :true-value="1" :false-value="0" hidden>
                <span><i class="fas fa-check"></i></span>
                <p>I understand that the price of Thrive Coach is likely to increase in the future, and I may not be
                  able to rejoin at my current locked-in-price.</p>
              </label>
            </li>
          </ul>

          <div class="action_wpr">
            <button type="button" class="btn cancel_btn" @click="deleteAccount()"
                    :disabled="!clause1 || !clause2 || !clause3">Cancel Subscription
            </button>
            <button type="button" class="btn save_btn" @click="hideCancellationPrompt()">Don't Cancel Subscription
            </button>
          </div>
        </div>
      </div>
    </div>
    <thrive-office v-model="officeModel"/>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import Toastr from '@/utils/Toastr'
import axios from '@/services/axios'
import {mapState, mapActions} from "vuex";

const ThriveOffice = defineAsyncComponent(() => import('@/pages/home/components/ThriveOffice'))
export default {
  name: "Cancellation",
  data() {
    return {
      active_tab: 0,
      confirmation_prompt: false,
      clause1: 0,
      clause2: 0,
      clause3: 0,
      officeModel: false,
      commentEmpty: false,
      comment: '',
      tags: [
        {'index': 1, 'label': 'Too Expensive', 'tag': 'too-expensive'},
        {'index': 2, 'label': 'Difficulty of Use', 'tag': 'difficulty-of-use'},
        {'index': 3, 'label': 'Missing Functionality', 'tag': 'missing-functionality'},
        {'index': 4, 'label': 'Using Other Product', 'tag': 'using-other-product'},
        {'index': 5, 'label': 'Not Using It', 'tag': 'not-using-it'},
        {'index': 6, 'label': 'Other (please specify)', 'tag': 'other'},
      ],
      billingApi: process.env.VUE_APP_BILLING_API_URL,
      coreApi: process.env.VUE_APP_CORE_URL,
    }
  },
  computed: {
    ...mapState({
      user: state => state.authModule.user,
    }),
  },
  components: {
    ThriveOffice
  },
  methods: {
    ...mapActions({
        logoutUser: 'authModule/logout',
    }),

    toggleChat() {
        window.gist.chat("open");
      this.sendPreCancellationEvent('chat');
    },
    viewPricing(event) {
      let previousTxt = event.target.innerHTML;
      event.target.innerHTML = 'Loading...';
      this.sendPreCancellationEvent('pricing').finally(() => {
        event.target.innerHTML = previousTxt;
        window.location = '/account-billing?tab=plans';
      });
    },
    bookCall() {
      window.open('https://thrivecoach.trafft.com/services/clarity-call', '_blank');
      this.sendPreCancellationEvent('book-call');
    },
    displayOfficeModel() {
      this.officeModel = true;
      this.sendPreCancellationEvent('office-model');
    },
    sendPreCancellationEvent(action) {
      let tag = this.tags.find(obj => obj.index === this.active_tab).tag;
      const vm = this;
      return axios.post(`${vm.coreApi}/v4/event/record`, {
        eventType: 'pre-cancellation',
        eventTag: tag,
        meta: {action: action}
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${vm.user.access_token}`,
        },
      });
    },
    displayCancellationPrompt() {
      if (this.comment == null || this.comment == '') {
        Toastr.error('Cancellation comment required');
        this.commentEmpty = true;
        let _this = this;
        window.setTimeout(function() {
          if(_this.commentEmpty) {
            _this.commentEmpty = false;
          }
        }, 2000);
      } else {
        this.commentEmpty = false;
        this.confirmation_prompt = true;
      }
    },
    hideCancellationPrompt() {
      this.confirmation_prompt = false;
    },
    deleteAccount() {
      this.confirmation_prompt = false;
      const vm = this;
      let tag = this.tags.find(obj => obj.index === this.active_tab).tag;
      axios.post(`${vm.billingApi}/stripe/subscription/cancel`, {cancel_tag: tag, cancel_reason: this.comment}, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${vm.user.access_token}`,
        },
      }).then((resp) => {
        vm.deleteText = '';

        Toastr.success(`Account has been deleted successfully!`);
        vm.logoutUser();

        setTimeout(function () {
          location.reload();
        }, 2000);

      }).catch((err) => {
        Toastr.error(err.response.data.error);
      });
    },
  }

}
</script>

<style scoped>
.container {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto 40px auto;
}

.cancel_heading {
  margin-bottom: 30px;
}

.cancel_heading h2 {
  font-size: 30px;
  line-height: 45px;
  font-weight: 400;
  color: #121525;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.cancel_heading h2 span {
  font-size: 24px;
  line-height: 36px;
  font-weight: 300;
  padding-top: 7px;
}

.cancel_heading h4 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  color: #121525;
}

.reasoning_list ul {
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -7px;
}

.reasoning_list ul li {
  padding: 7px;
}

.reasoning_list ul li label {
  padding: 7px 20px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #5a5a5a;
  position: relative;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.reasoning_list ul li.active label {
  border-color: #d5e7ff;
  color: #2f7eed;
}

.reasoning_details {
  padding: 35px 40px;
  border-radius: 10px;
  background: #fff;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reasoning_details h3 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  color: #121525;
  padding-bottom: 10px;
}

.reasoning_details p {
  font-size: 17px;
  line-height: 30px;
  font-weight: 300;
  color: #121525;
}

.reasoning_details p span {
  font-weight: 400;
}

.reasoning_details .cta_btn {
  padding: 10px 25px;
  background: #e5efff;
  border-radius: 5px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: #2f7eed;
  margin: 15px 0 30px 0;
  text-transform: uppercase;
  cursor: pointer;
}

.reasoning_details ul {
  padding-top: 15px;
}

.reasoning_details ul li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 30px;
  position: relative;
}

.reasoning_details ul li:before {
  position: absolute;
  content: '';
  top: 10px;
  left: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #5a5a5a;
}

.advance_option {
  width: 100%;
  margin-top: 30px;
}

.comment_wpr {
  margin: 20px 0 30px 0;
  max-width: 600px;
  width: 100%;
  background: #f9f9f9;
  border-radius: 5px;
  line-height: 0;
}

.comment_wpr textarea {
  width: 100%;
  min-height: 100px;
  padding: 15px;
  background: transparent;
  resize: vertical;
}

.advance_option .action_wpr {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.advance_option .action_wpr .btn {
  margin: 0 10px 10px 0;
}

.modal.prompt .modal_container {
  max-width: 600px;
  width: auto;
  background: #fff;
  padding: 0;
  margin: 10px;
}

.modal.prompt .modal_container .scroll_box {
  padding: 30px;
  max-height: 600px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal.prompt .close_btn {
  position: absolute;
  right: -7px;
  left: auto;
  top: -7px;
  border: 1px solid #c7c7c7;
  background: rgba(50, 55, 59, 0.7);
  color: #fff;
  border-radius: 50%;
  font-size: 11px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  z-index: 1;
}

.modal.prompt .logo_img {
  max-height: 20px;
  width: auto;
  margin-bottom: 30px;
}

.modal.prompt h3 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
  color: #121525;
  padding-bottom: 10px;
  text-align: center;
}

.modal.prompt h5 {
  font-size: 15px;
  line-height: 25px;
  color: #121525;
  font-weight: 300;
  text-align: center;
  margin-bottom: 20px;
}

.modal.prompt ul {
  padding: 10px 20px;
  background: #f9f9f9;
  border-radius: 5px;
  margin-bottom: 20px;
}

.modal.prompt ul li {
  padding: 10px 0;
}

.modal.prompt label.checkbox span {
  margin-top: 5px;
  background: #fff;
}

.modal.prompt label.checkbox p {
  font-size: 15px;
  line-height: 25px;
  color: #121525;
  font-weight: 300;
  padding-left: 12px;
}

.modal.prompt .action_wpr {
  justify-content: center;
  flex-wrap: wrap;
}

.modal.prompt .action_wpr .btn {
  margin: 5px;
}

.modal.prompt .action_wpr .btn[disabled] {
  cursor: no-drop;
}

@media (max-width: 499px) {
  .cancel_heading h2 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 12px;
  }

  .cancel_heading h2 span {
    font-size: 18px;
    line-height: 28px;
  }

  .cancel_heading h4 {
    font-size: 15px;
    line-height: 25px;
  }

  .reasoning_list ul {
    margin: 0 -5px;
  }

  .reasoning_list ul li {
    padding: 5px;
    width: 50%;
  }

  .reasoning_list ul li label {
    padding: 7px 10px;
    border-radius: 5px;
    font-size: 12px;
    line-height: 18px;
  }

  .reasoning_details {
    padding: 20px;
  }

  .reasoning_details h3 {
    font-size: 17px;
    line-height: 28px;
  }

  .reasoning_details ul li {
    padding-left: 24px;
  }

  .reasoning_details ul li:before {
    left: 6px;
    width: 5px;
    height: 5px;
  }

  .reasoning_details p {
    font-size: 15px;
    line-height: 27px;
  }

  .reasoning_details .cta_btn {
    padding: 8px 15px;
    font-size: 12px;
    line-height: 18px;
  }

  .advance_option .action_wpr .save_btn {
    order: -1;
  }

  .modal.prompt .modal_container .scroll_box {
    padding: 30px 20px;
  }

  .modal.prompt h3 {
    font-size: 18px;
    line-height: 28px;
  }

  .modal.prompt .action_wpr .save_btn {
    order: -1;
  }
}
</style>