<template>
    <div class="inner_pages">
        <!-- <InnerSidebar/> -->
        <div class="respective_content">
            <div class="acton_header m-0">
                <div class="left_section">
                    <h2>Workouts</h2>
                    <button type="button" class="video_btn" @click="tutorial = true;"><i class="fas fa-play"></i></button>
                    <button type="button" class="add_btn pointer" @click="newWorkout = true" v-if="tab === 'Workout'"><i class="fas fa-plus"></i>Add A Workout</button>
                    <button type="button" class="add_btn pointer" @click="newPlan = true" v-if="tab === 'Plan'"><i class="fas fa-plus"></i>Add A Plan</button>
                </div>
                <div class="right_section">
                    <ul>
                        <li @click="isLibrary = true;"><img src="@/assets/images/library.svg"></li>
                        <li @click="itemSetup('Sort Workout')" v-if="tab === 'Workout'"><img src="@/assets/images/slider2.svg"></li>
                        <li @click="itemSetup('Sort Plan')" v-if="tab === 'Plan'"><img src="@/assets/images/slider2.svg"></li>
                        <li @click="itemSetup('Import Workout')" v-if="tab === 'Workout'"><img src="@/assets/images/download.svg"></li>
                        <li @click="itemSetup('Import Plan')" v-if="tab === 'Plan'"><img src="@/assets/images/download.svg"></li>
                    </ul>
                </div>
            </div>
            <div class="result_wpr new">
                <div class="actions">
                    <ul>
                        <li class="p-0" v-show="selectedWorkout.length || selectedPlan.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedWorkout.length || selectedPlan.length ? `${selectedWorkout.length || selectedPlan.length} Selected` : `Select ${tab}s for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkOpt = !bulkOpt" v-if="selectedWorkout.length || selectedPlan.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkOpt ? 'active' : ''">
                                <ul>
                                    <li>Assign Contacts</li>
                                    <li>Unassign Contacts</li>
                                    <li class="danger">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="search_area ml-auto" :class="{'active': searchField}">
                            <input type="text" placeholder="Search"/>
                            <button class="search_btn" @click="searchField = !searchField;">
                                <i class="fas fa-search"></i>
                            </button>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="actionList = !actionList"  v-click-outside="closeSortingDropdown">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            Show 6 <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="perPageFilter" :type="1" :is-dropdown="true" ref="workout-per-page-filter" />
                        </li>
                    </ul>
                </div>
                <ul class="tab_sec">
                    <li :class="tab === 'Workout' ? 'active' : ''" @click="tab = 'Workout'">My Workouts</li>
                    <li :class="tab === 'Plan' ? 'active' : ''" @click="tab = 'Plan'">My Plans</li>
                </ul>
                <div class="action_content" v-if="tab === 'Workout'">
                    <ul class="playbook_list">
                        <li v-for="(workout, w) in workoutList" :key="w">
                            <div class="plybook_item" @mouseenter="showAction(true)" @mouseleave="showAction(false)">
                                <div class="item_img" :class="{ active : selectedWorkout.includes(workout.id) }">
                                    <img src="@/assets/images/thumb/default-progress.svg" class="img">
                                    <span class="replace_img" @click="itemSetup('Thumbnail')"><img src="@/assets/images/image.svg"></span>                                    
                                    <ul class="statistics">
                                        <li>
                                            <label :for="`workout-${workout.id}`" class="checkbox">
                                                <input type="checkbox" :id="`workout-${workout.id}`" :value="workout.id" v-model="selectedWorkout" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </li>
                                        <li>0 Assigned</li>
                                        <li>0 Submitted</li>
                                        <li>Hourly Reminder</li>
                                    </ul>
                                </div>
                                <a class="item_ttl pointer" @click="editWorkout = true">{{workout.title}}</a>
                                <div class="item_info">
                                    <div class="member pointer" @click="assignedMember = true">
                                        <img src="@/assets/images/member.svg">
                                        {{workout.assigned}} Assigned
                                    </div>
                                    <ul>
                                        <li @click="itemSetup('Get Workout Link')"><i class="fas fa-link"></i></li>
                                        <li @click="rowOption($event)">
                                            <i class="fas fa-ellipsis-v"></i>
                                            <ul class="row_option mr-4 py-1">
                                                <li><a @click="editWorkout = true">Edit</a></li>
                                                <li @click="itemSetup('Rename')">Rename</li>
                                                <li @click="manageContacts = true">Manage</li>
                                                <li @click="fullPreview = true">Preview</li>
                                                <li @click="shareWorkout = true">Share</li>
                                                <li>Assign Contacts</li>
                                                <li @click="confirmFunc('duplicate')">Duplicate</li>
                                                <li @click="confirmFunc('delete')" class="danger">Delete</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="empty_section" v-if="!workoutList.length">
                        <img src="@/assets/images/curve_arrow.svg" alt="" class="arrow">
                        <h1>Looks like you don't have any Workouts yet. Click the play button to get started.</h1>
                    </div>
                </div>
                <div class="action_content" v-if="tab === 'Plan'">
                    <ul class="playbook_list">
                        <li v-for="(plan, w) in myPlanList" :key="w">
                            <div class="plybook_item" @mouseenter="showAction(true)" @mouseleave="showAction(false)">
                                <div class="item_img" :class="{ active : selectedPlan.includes(plan.id) }">
                                    <img src="@/assets/images/thumb/default-habit.svg" class="img">
                                    <span class="replace_img" @click="itemSetup('Thumbnail')"><img src="@/assets/images/image.svg"></span>                                    
                                    <ul class="statistics">
                                        <li>
                                            <label :for="`plan-${plan.id}`" class="checkbox">
                                                <input type="checkbox" :id="`plan-${plan.id}`" :value="plan.id" v-model="selectedPlan" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </li>
                                        <li>0 Assigned</li>
                                        <li>0 Submitted</li>
                                        <li>Hourly Reminder</li>
                                    </ul>
                                </div>
                                <a class="item_ttl pointer" @click="editPlan = true">{{plan.title}}</a>
                                <div class="item_info">
                                    <div class="member pointer" @click="assignedMember = true">
                                        <img src="@/assets/images/member.svg">
                                        {{plan.assigned}} Assigned
                                    </div>
                                    <ul>
                                        <li @click="itemSetup('Get plan Link')"><i class="fas fa-link"></i></li>
                                        <li @click="rowOption($event)">
                                            <i class="fas fa-ellipsis-v"></i>
                                            <ul class="row_option mr-4 py-1">
                                                <li><a @click="editPlan = true">Edit</a></li>
                                                <li @click="itemSetup('Rename')">Rename</li>
                                                <li @click="manageContacts = true">Manage</li>
                                                <li @click="fullPreview = true">Preview</li>
                                                <li @click="sharePlan = true">Share</li>
                                                <li>Assign Contacts</li>
                                                <li @click="confirmFunc('duplicate')">Duplicate</li>
                                                <li @click="confirmFunc('delete')" class="danger">Delete</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="empty_section" v-if="!myPlanList.length">
                        <img src="@/assets/images/curve_arrow.svg" alt="" class="arrow">
                        <h1>Looks like you don't have any Plans yet. Click the play button to get started.</h1>
                    </div>
                </div>
                <div class="list_footer">
                    <ul>
                        <li>2-4 of 120</li>
                    </ul>
                </div>
                <div class="pagination">
                    <ul>
                        <li>1</li>
                        <li class="active">2</li>
                        <li>3</li>
                        <li>4</li>
                        <li><i class="fas fa-ellipsis-h"></i></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="modal" v-if="pageSetup" :class="{active : pageSetup}" @click.self="closeBar()">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>{{tab}} <span>{{sidebarHeader}}</span></h1>
                    </div>
                    <button class="close_btn left_out" @click="pageSetup = false"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="setting_wpr" v-if="sidebarHeader == 'Rename'">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">{{tab}} Name</label>
                                <div class="field_wpr">
                                    <input type="text" placeholder="Workout 1" >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr" v-if="sidebarHeader == 'Get Workout Link'">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Copy {{tab}} Link:</label>
                                <div class="field_wpr has_suffix">
                                    <input type="text" placeholder="https://onboardme.net/a38966" >
                                    <span class="suffix">Copy</span>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div class="setting_wpr" v-if="sidebarHeader == 'Sort Workout'">
                        <ul class="sorting_card">
                            <draggable v-model="workoutList">
                                <template #item="{element}">
                                    <li class="move">
                                        <img :src="require(`@/assets/images/thumb/${element.img}.svg`)" alt="">
                                        <div class="card_details">{{element.title}}<p>Maecenas fringilla imperdiet hendrerit.</p></div>
                                    </li>
                                </template>
                            </draggable>
                        </ul>                   
                    </div>
                    <div class="setting_wpr" v-if="sidebarHeader == 'Sort Plan'">
                        <ul class="sorting_card">
                            <draggable v-model="myPlanList">
                                <template #item="{element}">
                                    <li class="move">
                                        <img :src="require(`@/assets/images/thumb/${element.img}.svg`)" alt="">
                                        <div class="card_details">{{element.title}}<p>Maecenas fringilla imperdiet hendrerit.</p></div>
                                    </li>
                                </template>
                            </draggable>
                        </ul>                   
                    </div>
                    <div class="setting_wpr" v-if="sidebarHeader == 'Import Workout'">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Paste Share Link Here</label>
                                <div class="field_wpr">
                                    <input type="text" placeholder="https://onboardme.net/a38966" >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr" v-if="sidebarHeader == 'Import Plan'">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Paste Share Link Here</label>
                                <div class="field_wpr">
                                    <input type="text" placeholder="https://onboardme.net/a38966" >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="setting_wpr" v-if="sidebarHeader == 'Thumbnail'">
                        <h3 class="sub_header mb-4">Dashboard Thumbnail</h3>
                        <image-library v-model="dashboard_thumb" image-type="dashboard-thumb" upload-text="Image" />
                    </div>
                </div>
                <div class="modal_footer">
                    <button class="btn cancel_btn" @click="pageSetup = false">Cancel</button>
                    <button class="btn save_btn" v-if="sidebarHeader == 'Sort Workout'">Reset</button>
                    <button class="btn save_btn" v-if="sidebarHeader !== 'Import Workout'">Save</button>
                    <button class="btn save_btn" v-if="sidebarHeader == 'Import Workout'">Import</button>
                </div>
            </div>
        </div>
        <div class="modal profile_modal share_playbook" v-if="shareWorkout || sharePlan" @click.self="closeBar()">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1 class="m-0">{{tab}}<span>Share {{tab}}</span></h1>
                    <button class="close_btn" @click="shareWorkout = false; sharePlan = false;"><i class="fas fa-times"></i></button>
                    <ul class="tab_row">
                        <li :class="shareTab === 'limited' ? 'active' : ''" @click="shareTab = 'limited'">Limited Share History</li>
                        <li :class="shareTab === 'unlimited' ? 'active' : ''" @click="shareTab = 'unlimited'">Unlimited Share History</li>
                    </ul>
                </div>
                <div class="modal_body">
                    <div v-if="shareTab === 'limited'" class="tab_wpr">
                        <div class="setting_wpr mt-3">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Copy Share link:</label>
                                    <div class="field_wpr has_suffix">
                                        <input type="text" placeholder="https://onboardme.net/a38966" >
                                        <span class="suffix">Copy</span>
                                    </div>
                                </div>
                            </div>                        
                        </div>
                        <div class="result_wpr">
                            <div class="actions">
                                <ul>
                                    <li class="edit">
                                        <label for="check" class="checkbox">
                                            <input type="checkbox" id="check" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                        </label>
                                    </li>
                                    <li class="optionDrops sort_list" @click="pageFilter = !pageFilter">
                                        5 <i  class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="pageFilter ? 'active' : ''">
                                            <ul>
                                                <li>5</li>
                                                <li>10</li>
                                                <li>15</li>
                                                <li>20</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="optionDrops" @click="bulkAction = !bulkAction">
                                        Bulk Action<i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="bulkAction ? 'active' : ''">
                                            <ul>
                                                <li>Delete Assets</li>
                                                <li>Enable Assets</li>
                                                <li>Disable Assets</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li><input type="text" class="p-0" placeholder="Search"/><i class="fas fa-search"></i></li>
                                </ul>
                            </div>
                            <table class="standard">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>E-mail</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <label for="check1" class="checkbox">
                                                <input type="checkbox" id="check1" value="Robert Bacins" v-model="tablerowCheck" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </td>
                                        <td>
                                            <div class="user_wpr" @click="userProfile = true">
                                                <h4>Robert Bacins</h4>
                                            </div>
                                        </td>
                                        <td><div class="email_wpr">hello.robert@gmail.com</div></td>
                                        <td>14th Dec 21</td>
                                        <td>
                                            <label for="permission1" class="switch_option capsule_btn" @click="confirmAction = true">
                                                <input type="checkbox" id="permission1" v-model="permission.item1" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </td>
                                        <td class="action"><a @click="userSetting($event)"><i class="fas fa-ellipsis-v"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label for="check2" class="checkbox">
                                                <input type="checkbox" id="check2" value="Chris Damon" v-model="tablerowCheck" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </td>
                                        <td>
                                            <div class="user_wpr" @click="userProfile = true">
                                                <h4>Chris Damon</h4>
                                            </div>
                                        </td>
                                        <td><div class="email_wpr">hello.chris@gmail.com</div></td>
                                        <td>16th Dec 21</td>
                                        <td>
                                            <label for="permission2" class="switch_option capsule_btn" @click="confirmAction = true">
                                                <input type="checkbox" id="permission2" v-model="permission.item2" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </td>
                                        <td class="action"><a @click="userSetting($event)"><i class="fas fa-ellipsis-v"></i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pagination pb-4 mt-4">
                            <ul>
                                <li>1</li>
                                <li class="active">2</li>
                                <li>3</li>
                                <li>4</li>
                                <li><i class="fas fa-ellipsis-h"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="shareTab === 'unlimited'" class="tab_wpr">
                        <div class="setting_wpr mt-3">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Copy Share link:</label>
                                    <div class="field_wpr has_suffix">
                                        <input type="text" placeholder="https://onboardme.net/a38966" >
                                        <span class="suffix">Copy</span>
                                    </div>
                                </div>
                            </div>                        
                        </div>
                        <div class="result_wpr">
                            <div class="actions">
                                <ul>
                                    <li class="optionDrops sort_list" @click="pageFilter = !pageFilter">
                                        5 <i  class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="pageFilter ? 'active' : ''">
                                            <ul>
                                                <li>5</li>
                                                <li>10</li>
                                                <li>15</li>
                                                <li>20</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li><input type="text" class="p-0" placeholder="Search"/><i class="fas fa-search"></i></li>
                                </ul>
                            </div>
                            <table class="standard">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>E-mail</th>
                                        <th>Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <div class="user_wpr" @click="userProfile = true">
                                                <h4>Robert Bacins</h4>
                                            </div>
                                        </td>
                                        <td><div class="email_wpr">hello.robert@gmail.com</div></td>
                                        <td>14th Dec 21</td>
                                        <td class="action"><a @click="userSetting($event)"><i class="fas fa-ellipsis-v"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <div class="user_wpr" @click="userProfile = true">
                                                <h4>Chris Damon</h4>
                                            </div>
                                        </td>
                                        <td><div class="email_wpr">hello.chris@gmail.com</div></td>
                                        <td>16th Dec 21</td>
                                        <td class="action"><a @click="userSetting($event)"><i class="fas fa-ellipsis-v"></i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pagination pb-4 mt-4">
                            <ul>
                                <li>1</li>
                                <li class="active">2</li>
                                <li>3</li>
                                <li>4</li>
                                <li><i class="fas fa-ellipsis-h"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button class="btn cancel_btn" @click="shareWorkout = false; sharePlan = false;">Cancel</button>
                </div>
            </div>
        </div>
        <!-- <div class="preview_area" v-if="manageContacts" @click.self="closeBar()">
            <div class="report_section py-4">
                <div class="progress_container">
                    <div class="section_header mt-5"><h2>Leaderboard</h2></div>
                    <button class="close_btn" @click="manageContacts = false"><i class="fas fa-times"></i></button>
                    <div class="leaderboard_wpr">
                        <div class="leaderboard">
                            <div class="board_ttl">Participation Leaderboard <i class="fas fa-trophy"></i></div>
                            <ul>
                                <li v-for="(item, i) in leaderBoard" :key="i">
                                    <span class="board_pos">{{i + 1}}</span>
                                    <img :src="require(`@/assets/images/${item.img}`)" alt="">
                                    <h5>{{item.name}}</h5>
                                    <div class="score_circle">
                                        <div class="inner_circle">
                                            <div class="score">{{item.score}}<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24">
                                            <circle cx="12" cy="12" r="11.5" stroke-linecap="round" fill="none" stroke-width="1px" stroke="#2f7eed" :stroke-dasharray="70" :stroke-dashoffset="70 - (item.score / 100) * 70 "/>
                                        </svg>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="leaderboard">
                            <div class="board_ttl">Progress Leaderboard <i class="fas fa-trophy"></i></div>
                            <ul>
                                <li v-for="(item, i) in leaderBoard" :key="i">
                                    <span class="board_pos">{{i + 1}}</span>
                                    <img :src="require(`@/assets/images/${item.img}`)" alt="">
                                    <h5>{{item.name}}</h5>
                                    <div class="score_circle">
                                        <div class="inner_circle">
                                            <div class="score">{{item.score}}<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24">
                                            <circle cx="12" cy="12" r="11.5" stroke-linecap="round" fill="none" stroke-width="1px" stroke="#2f7eed" :stroke-dasharray="70" :stroke-dashoffset="70 - (item.score / 100) * 70 "/>
                                        </svg>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="leaderboard overall">
                            <div class="board_ttl">Overall Leaderboard <i class="fas fa-trophy"></i></div>
                            <ul>
                                <li v-for="(item, i) in leaderBoard" :key="i">
                                    <span class="board_pos">{{i + 1}}</span>
                                    <img :src="require(`@/assets/images/${item.img}`)" alt="">
                                    <h5>{{item.name}}</h5>
                                    <div class="score_circle">
                                        <div class="inner_circle">
                                            <div class="score">{{item.score}}<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24">
                                            <circle cx="12" cy="12" r="11.5" stroke-linecap="round" fill="none" stroke-width="1px" stroke="#2f7eed" :stroke-dasharray="70" :stroke-dashoffset="70 - (item.score / 100) * 70 "/>
                                        </svg>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="section_header mt-4"><h2>Manage Contacts</h2></div>
                    <ul class="tab_row border-bottom mb-5">
                        <li :class="manageTab === 'active' ? 'active' : ''" @click="manageTab = 'active'">Active</li>
                        <li :class="manageTab === 'deleted' ? 'active' : ''" @click="manageTab = 'deleted'">Deleted</li>
                    </ul>
                    <div class="tab_wpr" v-if="manageTab === 'active'">
                        <div class="result_wpr">
                            <div class="actions">
                                <ul>
                                    <li class="optionDrops sort_list" @click="pageFilter = !pageFilter">
                                        5 <i  class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="pageFilter ? 'active' : ''">
                                            <ul>
                                                <li>5</li>
                                                <li>10</li>
                                                <li>15</li>
                                                <li>20</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="optionDrops" @click="filterList = !filterList">
                                        Highest Overall Score<i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="filterList ? 'active' : ''">
                                            <ul>
                                                <li>Highest Overall Score</li>
                                                <li>Highest Participation Score</li>
                                                <li>Highest Progress Score</li>
                                                <li>Lowest Overall score</li>
                                                <li>Lowest Participation score</li>
                                                <li>Lowest Progress score</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li><input type="text" class="p-0" placeholder="Search"/><i class="fas fa-search"></i></li>
                                    <li class="list_info">1-5 of <span>50</span></li>
                                </ul>
                            </div>
                            <table class="standard">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Submissions</th>
                                        <th>Participation</th>
                                        <th>Reminder</th>
                                        <th>Actions</th>
                                        <th>Progress</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="user_wpr" @click="userProfile = true">
                                                <h4>Robert Bacins</h4>
                                            </div>
                                        </td>
                                        <td><div class="email_wpr">Hello.robert@gmail.com</div></td>
                                        <td>2/9</td>
                                        <td>
                                            <div class="score_circle" style="background: #d9d9d9; width:30px; height:30px; padding: 2px;">
                                                <div class="inner_circle">
                                                    <div class="score">50<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="88" :stroke-dashoffset="44"/>
                                                </svg>
                                            </div>
                                        </td>
                                        <td>
                                            <label for="reminder1" class="switch_option capsule_btn p-0" @click="confirmAction = true">
                                                <input type="checkbox" id="reminder1" v-model="reminder.item1" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </td>
                                        <td>
                                            <ul class="action_list">
                                                <li @click="confirmFunc('sms')" title="SMS Reminder"><i class="fas fa-comment"></i></li>
                                                <li @click="confirmFunc('email')" title="Email Reminder"><i class="fas fa-envelope"></i></li>
                                                <li @click="confirmFunc('delete')" title="Delete"><i class="fas fa-trash-alt danger"></i></li>
                                                <li @click="contactProgress = true" title="Progress Details"><i class="fas fa-search-plus"></i></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <div class="score_circle" style="background: #d9d9d9; width:30px; height:30px; padding: 2px;">
                                                <div class="inner_circle">
                                                    <div class="score">50<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="88" :stroke-dashoffset="44"/>
                                                </svg>
                                            </div>
                                        </td>
                                        <td class="action"><a @click="userSetting($event)"><i class="fas fa-ellipsis-v"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="user_wpr" @click="userProfile = true">
                                                <h4>Chris Damon</h4>
                                            </div>
                                        </td>
                                        <td><div class="email_wpr">Hello.chris@gmail.com</div></td>
                                        <td>3/11</td>
                                        <td>
                                            <div class="score_circle" style="background: #d9d9d9; width:30px; height:30px; padding: 2px;">
                                                <div class="inner_circle">
                                                    <div class="score">50<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="88" :stroke-dashoffset="44"/>
                                                </svg>
                                            </div>
                                        </td>
                                        <td>
                                            <label for="reminder2" class="switch_option capsule_btn p-0" @click="confirmAction = true">
                                                <input type="checkbox" id="reminder2" v-model="reminder.item2" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </td>
                                        <td>
                                            <ul class="action_list">
                                                <li @click="confirmFunc('sms')" title="SMS Reminder"><i class="fas fa-comment"></i></li>
                                                <li @click="confirmFunc('email')" title="Email Reminder"><i class="fas fa-envelope"></i></li>
                                                <li @click="confirmFunc('delete')" title="Delete"><i class="fas fa-trash-alt danger"></i></li>
                                                <li @click="contactProgress = true" title="Progress Details"><i class="fas fa-search-plus"></i></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <div class="score_circle" style="background: #d9d9d9; width:30px; height:30px; padding: 2px;">
                                                <div class="inner_circle">
                                                    <div class="score">50<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="88" :stroke-dashoffset="44"/>
                                                </svg>
                                            </div>
                                        </td>
                                        <td class="action"><a @click="userSetting($event)"><i class="fas fa-ellipsis-v"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="user_wpr" @click="userProfile = true">
                                                <h4>Jane Doe</h4>
                                            </div>
                                        </td>
                                        <td><div class="email_wpr">Hello.jane@gmail.com</div></td>
                                        <td>5/9</td>
                                        <td>
                                            <div class="score_circle" style="background: #d9d9d9; width:30px; height:30px; padding: 2px;">
                                                <div class="inner_circle">
                                                    <div class="score">50<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="88" :stroke-dashoffset="44"/>
                                                </svg>
                                            </div>
                                        </td>
                                        <td>
                                            <label for="reminder3" class="switch_option capsule_btn p-0" @click="confirmAction = true">
                                                <input type="checkbox" id="reminder3" v-model="reminder.item3" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </td>
                                        <td>
                                            <ul class="action_list">
                                                <li @click="confirmFunc('sms')" title="SMS Reminder"><i class="fas fa-comment"></i></li>
                                                <li @click="confirmFunc('email')" title="Email Reminder"><i class="fas fa-envelope"></i></li>
                                                <li @click="confirmFunc('delete')" title="Delete"><i class="fas fa-trash-alt danger"></i></li>
                                                <li @click="contactProgress = true" title="Progress Details"><i class="fas fa-search-plus"></i></li>
                                            </ul>
                                        </td>
                                        <td>
                                            <div class="score_circle" style="background: #d9d9d9; width:30px; height:30px; padding: 2px;">
                                                <div class="inner_circle">
                                                    <div class="score">50<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="88" :stroke-dashoffset="44"/>
                                                </svg>
                                            </div>
                                        </td>
                                        <td class="action"><a @click="userSetting($event)"><i class="fas fa-ellipsis-v"></i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pagination pb-4 mt-4">
                            <ul>
                                <li>1</li>
                                <li class="active">2</li>
                                <li>3</li>
                                <li>4</li>
                                <li><i class="fas fa-ellipsis-h"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab_wpr" v-if="manageTab === 'deleted'">
                        <div class="result_wpr">
                            <div class="actions">
                                <ul>
                                    <li class="optionDrops sort_list" @click="pageFilter = !pageFilter">
                                        5 <i  class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="pageFilter ? 'active' : ''">
                                            <ul>
                                                <li>5</li>
                                                <li>10</li>
                                                <li>15</li>
                                                <li>20</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="optionDrops" @click="filterList = !filterList">
                                        Highest Overall Score<i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="filterList ? 'active' : ''">
                                            <ul>
                                                <li>Highest Overall Score</li>
                                                <li>Highest Participation Score</li>
                                                <li>Highest Progress Score</li>
                                                <li>Lowest Overall score</li>
                                                <li>Lowest Participation score</li>
                                                <li>Lowest Progress score</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li><input type="text" class="p-0" placeholder="Search"/><i class="fas fa-search"></i></li>
                                    <li class="list_info">1-5 of <span>50</span></li>
                                </ul>
                            </div>
                            <table class="standard">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Submissions</th>
                                        <th>Participation</th>
                                        <th>Reminder</th>
                                        <th>Actions</th>
                                        <th>Progress</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="user_wpr" @click="userProfile = true">
                                                <h4>Robert Bacins</h4>
                                            </div>
                                        </td>
                                        <td><div class="email_wpr">Hello.robert@gmail.com</div></td>
                                        <td>2/9</td>
                                        <td>
                                            <div class="score_circle" style="background: #d9d9d9; width:30px; height:30px; padding: 2px;">
                                                <div class="inner_circle">
                                                    <div class="score">50<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="88" :stroke-dashoffset="44"/>
                                                </svg>
                                            </div>
                                        </td>
                                        <td>
                                            <label for="reminder4" class="switch_option capsule_btn p-0" @click="confirmAction = true">
                                                <input type="checkbox" id="reminder4" v-model="reminder.item4" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </td>
                                        <td>
                                            <ul class="action_list">
                                                <li @click="confirmFunc('sms')" title="SMS Reminder"><i class="fas fa-comment"></i></li>
                                                <li @click="confirmFunc('email')" title="Email Reminder"><i class="fas fa-envelope"></i></li>
                                                <li @click="contactProgress = true" title="Progress Details"><i class="fas fa-search-plus"></i></li>
                                            </ul>
                                        </td>
                                        <td class="action"><a @click="userSetting($event)"><i class="fas fa-ellipsis-v"></i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pagination pb-4 mt-4">
                            <ul>
                                <li>1</li>
                                <li class="active">2</li>
                                <li>3</li>
                                <li>4</li>
                                <li><i class="fas fa-ellipsis-h"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        
        <div class="modal profile_modal" v-if="userProfile" :class="{active : userProfile}" @click.self="closeBar()">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="userProfile = false"><i class="fas fa-times"></i></button>
                    <ul class="tab_sec">
                        <li @click="profileTab = 'ProfileDetails'" :class="{active: profileTab === 'ProfileDetails'}">Details</li>
                        <li @click="profileTab = 'ProfileLifecycles'" :class="{active: profileTab === 'ProfileLifecycles'}">Lifecycle</li>
                        <li @click="profileTab = 'ProfileNotes'" :class="{active: profileTab === 'ProfileNotes'}">Notes</li>
                        <li @click="profileTab = 'ProfileForm'" :class="{active: profileTab === 'ProfileForm'}">Form</li>
                        <li @click="profileTab = 'ProfileFiles'" :class="{active: profileTab === 'ProfileFiles'}">Files</li>
                        <li @click="profileTab = 'ProfileReward'" :class="{active: profileTab === 'ProfileReward'}">Rewards</li>
                        <li @click="profileTab = 'ProfileChat'" :class="{active: profileTab === 'ProfileChat'}">Chat</li>
                        <li @click="profileTab = 'ProfileEmail'" :class="{active: profileTab === 'ProfileEmail'}">Email Sent</li>
                        <li @click="profileTab = 'ProfileProgress'" :class="{active: profileTab === 'ProfileProgress'}">Prgress</li>
                        <li @click="profileTab = 'ProfileHabit'" :class="{active: profileTab === 'ProfileHabit'}">Habit</li>
                    </ul>
                </div>
                <div class="modal_body">
                    <div class="profile_sidebar">
                        <div class="profile_header">
                            <button class="option"><i class="fas fa-ellipsis-v"></i></button>
                            <img src="@/assets/images/profile.png" alt="">
                            <h4>Michele Rosiello</h4>
                            <h6>4 Days</h6>
                        </div>
                        <ul class="profile_info">
                            <li>
                                <h5>Member ID :</h5>
                                <p>Mandrill768!</p>
                            </li>
                            <li>
                                <h5>E-mail :</h5>
                                <p>Rosiello@onboardme.io</p>
                            </li><li>
                                <h5>Phone :</h5>
                                <p>+18433439321</p>
                            </li>
                        </ul>
                        <ul class="action_status">
                            <li><div class="circular_bar"><span>86%</span></div></li>
                            <li>
                                <h2>25</h2>
                                <p>Email Sent</p>
                            </li>
                            <li>
                                <h2>42</h2>
                                <p>Opened</p>
                            </li>   
                        </ul>
                        <ul class="action_status">
                            <li><div class="circular_bar orange"><span>92%</span></div></li>
                            <li>
                                <h2>68</h2>
                                <p>SMS Sent</p>
                            </li>
                            <li>
                                <h2>18</h2>
                                <p>Replied</p>
                            </li>
                        </ul>
                    </div>
                    <div class="profileTab_content">
                        <keep-alive>
                            <component :is="profileTab"></component>
                        </keep-alive>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" v-if="tableUser" :class="{active : tableUser}" @click.self="closeBar()">
            <div class="modal_container">
                <div class="modal_header">
                    <div class="modal_info">
                        <h1>Launch Pad</h1>
                        <div class="user_info">
                            <img src="@/assets/images/table-user.png" alt="">
                            <div>
                                <h5 v-if="rowInfo.name">{{rowInfo.name}}</h5>
                                <h6 v-if="rowInfo.email">{{rowInfo.email}}</h6>
                            </div>
                        </div>
                    </div>
                    <button class="close_btn left_out" @click="tableUser = false"><i class="fas fa-long-arrow-alt-right"></i></button>
                    <ul class="tab_sec">
                        <li @click="activeTab2 = 'Actions'" :class="{active: activeTab2 === 'Actions'}">Actions</li>
                        <li @click="activeTab2 = 'ToolSettings'" :class="{active: activeTab2 === 'ToolSettings'}">Settings</li>
                        <li @click="activeTab2 = 'ToolAdvanced'" :class="{active: activeTab2 === 'ToolAdvanced'}">Advanced</li>
                    </ul>
                </div>
                <div class="modal_body">
                    <div class="tab_content">
                        <keep-alive>
                            <component :is="activeTab2"></component>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
        <AddForm :close="closeBar" v-if="newPage"/>
        <div class="modal secondary" v-if="confirmScreen" :class="{active : confirmScreen}">
            <div class="modal_container">
                <img src="@/assets/images/alert.svg" alt="" class="alert_img">
                <h2>Are You Sure !</h2>
                <h5 v-if="confirmInfo === 'duplicate'">You want to duplicate this {{tab}}.</h5>
                <h5 v-if="confirmInfo === 'sms'">You want to resend SMS reminder for this contact.</h5>
                <h5 v-if="confirmInfo === 'email'">You want to resend email reminder for this contact.</h5>
                <div v-if="confirmInfo === 'delete'" class="setting_wpr">
                    <h6>This action is permanent. You will not be able to recover these {{tab}}. Please type DELETE to remove these contacts.</h6>
                    <div class="form_grp">
                        <div class="group_item">
                            <div class="field_wpr has_suffix">
                                <input type="text" placeholder="DELETE" >
                                <span class="suffix">Delete</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="action_wpr mt-4">
                    <button class="btn mx-1" :class="confirmInfo === 'delete' ? 'save_btn' : 'cancel_btn'" @click="confirmScreen = false">Cancel</button>
                    <button class="btn save_btn mx-1" v-if="confirmInfo !== 'delete'">Yes</button>
                </div>
            </div>
        </div>
        <div class="modal secondary" v-if="confirmAction">
            <div class="modal_container">
                <img src="@/assets/images/alert.svg" alt="" class="alert_img">
                <h2>Are You Sure !</h2>
                <h5>You want to disable the reminder.</h5>
                <div class="action_wpr mt-4">
                    <button class="btn cancel_btn mx-1" @click="confirmAction = false">Cancel</button>
                    <button class="btn save_btn mx-1">Yes</button>
                </div>
            </div>
        </div>
        <Wizard v-model="newWorkout"/>
        <Wizard v-model="editWorkout"/>
        <WizardPlan v-model="newPlan"/>
        <WizardPlan v-model="editPlan"/>
        <ExerciseLibrary v-model="isLibrary"/>
        <div class="preview_area" v-if="contactProgress">
            <button class="close_btn pointer" @click="contactProgress = false"><i class="fas fa-times"></i></button>
            <Report/>
        </div>
        <div class="modal secondary video_player" v-if="tutorial">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <iframe src="https://www.loom.com/embed/1ee91264b13e4d68bf0f6ae6e6b900c3?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
                <div class="modal_footer">
                    <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
        <!-- <PublicPagePreview v-model="fullPreview" :infolist="collectedForm"/> -->
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import Draggable from 'vuedraggable'
import ImageLibrary from '@/components/image-library/ImageLibrary'

// const InnerSidebar = defineAsyncComponent(() => import('@/components/InnerSidebar'))
const PageFilter = defineAsyncComponent(() => import('@/components/PageFilter'))
const ProfileDetails = defineAsyncComponent(() => import('@/components/ProfileDetails'))
const ProfileLifecycles = defineAsyncComponent(() => import('@/components/ProfileLifecycles'))
const ProfileNotes = defineAsyncComponent(() => import('@/components/ProfileNotes'))
const ProfileForm = defineAsyncComponent(() => import('@/components/ProfileForm'))
const ProfileFiles = defineAsyncComponent(() => import('@/components/ProfileFiles'))
const ProfileReward = defineAsyncComponent(() => import('@/components/ProfileReward'))
const ProfileEmail = defineAsyncComponent(() => import('@/components/ProfileEmail'))
const ProfileChat = defineAsyncComponent(() => import('@/components/ProfileChat'))
const ProfileProgress = defineAsyncComponent(() => import('@/components/ProfileProgress'))
const ProfileHabit = defineAsyncComponent(() => import('@/components/ProfileHabit'))
const Actions = defineAsyncComponent(() => import('@/components/Actions'))
const ToolSettings = defineAsyncComponent(() => import('@/components/ToolSettings'))
const ToolAdvanced = defineAsyncComponent(() => import('@/components/ToolAdvanced'))
const NotificationSetting = defineAsyncComponent(() => import('@/components/NotificationSetting'))
const Wizard = defineAsyncComponent(() => import('./components/Wizard'))
const WizardPlan = defineAsyncComponent(() => import('./components/WizardPlan'))
const ExerciseLibrary = defineAsyncComponent(() => import('./components/ExerciseLibrary'))
const PublicPagePreview = defineAsyncComponent(() => import('./components/PreviewPage.vue'))
const Report = defineAsyncComponent(() => import('./components/Report.vue'))

export default {
    name: "Progress",
    components:{
        // InnerSidebar,
        ProfileDetails,
        ProfileLifecycles,
        ProfileNotes,
        ProfileForm,
        ProfileFiles,
        ProfileReward,
        ProfileEmail,
        ProfileChat,
        ProfileProgress,
        ProfileHabit,
        Actions,
        ToolSettings,
        ToolAdvanced,
        NotificationSetting,
        Draggable,
        ImageLibrary,
        Wizard,
        WizardPlan,
        ExerciseLibrary,
        // PublicPagePreview,
        Report
    },
    data(){
        return{
            tab: 'Workout',
            tutorial: false,
            actionList: false,
            pageSetup: false,
            sidebarHeader: '',
            shareWorkout: false,
            sharePlan : false,
            tablerowCheck: [],
            pageFilter: false,
            bulkOpt: false,
            bulkAction: false,
            shareTab: 'limited',
            manageTab: 'active',
            filterList: false,
            newWorkout: false,
            newPlan: false,
            editWorkout: false,
            editPlan: false,
            isLibrary: false,
            userProfile: false,
            tableUser: false,
            profileTab: 'ProfileDetails',
            activeTab2: 'Actions',
            dashboard_thumb: '',
            searchField: false,
            perPageFilter: 6,
            showContent: false,
            selectedWorkout: [],
            selectedPlan: [],
            collectedForm:{
                setGoal: true,
                clientAvaiibility: true,
                showSectionlabel: true,
                labelText: 'Progress Questions',
                weight: true,
                bodyFat: true,
                circumference: true,
                circumferenceItem:[
                    {
                        id: 1,
                        name: 'Neck',
                        status: true,
                    },
                    {
                        id: 2,
                        name: 'Shoulders',
                        status: true,
                    },
                    {
                        id: 3,
                        name: 'Biceps',
                        status: true,
                    },
                    {
                        id: 4,
                        name: 'Chest',
                        status: true,
                    },
                    {
                        id: 5,
                        name: 'Belly Button',
                        status: true,
                    },
                    {
                        id: 6,
                        name: 'Waist',
                        status: true,
                    },
                    {
                        id: 7,
                        name: 'Hips',
                        status: true,
                    },
                    {
                        id: 8,
                        name: 'Thigh',
                        status: true,
                    },
                    {
                        id: 9,
                        name: 'Calf',
                        status: true,
                    },
                ],
                lenUnit: {
                    mode: 'single',
                    value: ['Inches'],
                    options: [
                        { value: 'Inches', label: 'Inches' },
                        { value: 'Centimeters', label: 'Centimeters' }
                    ],
                },
                unit: {
                    mode: 'single',
                    value: ['Pounds'],
                    options: [
                        { value: 'Pounds', label: 'Pounds' },
                        { value: 'Kilogram', label: 'Kilogram' }
                    ],
                },
                progressPic: false,
                customQuestions: false,
            },
            rowInfo:{
                name: '',
                email: '',
            },
            returnFolder: false,
            users: {
                mode: 'tags',
                value: [],
                options: [
                    { value: 'Robert Bacins', label: 'Robert Bacins' },
                    { value: 'Chris Damon', label: 'Chris Damon' },
                    { value: 'Jane Doe', label: 'Jane Doe' },
                    { value: 'Matt Evans', label: 'Matt Evans' },
                ]
            },
            location: {
                mode: 'tags',
                value: [],
                options: [
                    { value: 'Location 1', label: 'Location 1' },
                    { value: 'Location 2', label: 'Location 2' },
                ]
            },
            confirmScreen: false,
            confirmInfo: '',
            workoutList:[
                {
                    id: 1,
                    title: 'Workout 1',
                    img: 'default-progress',
                    assigned: 5,
                },
                {
                    id: 2,
                    title: 'Workout 2',
                    img: 'default-progress',
                    assigned: 7,
                },
                {
                    id: 3,
                    title: 'Workout 3',
                    img: 'default-progress',
                    assigned: 12,
                },
            ],
            myPlanList:[
                {
                    id: 1,
                    title: 'Plan 1',
                    img: 'default-habit',
                    assigned: 5,
                },
                {
                    id: 2,
                    title: 'Plan 2',
                    img: 'default-habit',
                    assigned: 7,
                },
                {
                    id: 3,
                    title: 'Plan 3',
                    img: 'default-habit',
                    assigned: 12,
                },
            ],
            filterTitle: 'Custom Arrangement',
            filters: [
                { title: 'Custom Arrangement', field: 'ordering', order: 'desc'},
                { title: 'A-Z', field: 'title', order: 'asc'},
                { title: 'Z-A', field: 'title', order: 'desc'},
                { title: 'Date Created', field: 'id', order: 'desc'},
                { title: 'Date Modified', field: 'updated_at', order: 'desc'},
            ],
            resendModal: false,
            actionInfo: '',
            manageContacts: false,
            newPage: false,
            folder: 'main',
            showFolder: false,
            permission:{
                item1: true,
                item2: true,
            },
            confirmAction: false,
            reminder:{
                item1: true,
                item2: true,
                item3: true,
                item4: false,
            },
            pageConfirm: false,
            redirectOption: 'sorry',
            assetList: {
                mode: 'single',
                value: [],
                options: [
                    { value: 'Test Playbook', label: 'Test Playbook' },
                    { value: 'Test Form', label: 'Test Form' },
                    { value: 'Newsletter', label: 'Newsletter' }
                ],
                searchable: true,
            },
            fullPreview: false,
            contactProgress: false,
            leaderBoard:[
                {
                    name: "Robert Bacins",
                    img: "robert.png",
                    score: 65,
                },
                {
                    name: "Chris Damons",
                    img: "chris.png",
                    score: 62,
                },
                {
                    name: "Jane Doe",
                    img: "jane.png",
                    score: 57,
                },
                {
                    name: "Matt Evans",
                    img: "matt.png",
                    score: 45,
                },
                {
                    name: "Emma Stone",
                    img: "emma.png",
                    score: 39,
                },
                {
                    name: "Kristen Heard",
                    img: "kristen.png",
                    score: 36,
                },
                {
                    name: "Jessica Davis",
                    img: "jessica.png",
                    score: 28,
                },
                {
                    name: "Jake Gyllenhaal",
                    img: "matt.png",
                    score: 57,
                },
                {
                    name: "Oscar Isaac",
                    img: "chris.png",
                    score: 57,
                },
                {
                    name: "Cillian Murphy",
                    img: "kristen.png",
                    score: 57,
                },
            ]
        }
    },
    watch:{
        pageSetup(val){
            if (val) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        },
        shareWorkout(val){
           if (val) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            } 
        },
        sharePlan(val){
           if (val) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            } 
        },
        manageContacts(val){
            if (val) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            } 
        },
        newWorkout(val){
            if (val) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            } 
        },
        newPlan(val){
            if (val) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            } 
        },
        editWorkout(val){
            if (val) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            } 
        },
        editPlan(val){
            if (val) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            } 
        },
        isLibrary(val){
            if (val) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            } 
        },
        fullPreview(val){
            if (val) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            } 
        },
        manageTab(val){
            if(val){
                this.filterList = false;
            }
        }
    },
    methods:{
        rowOption(e){
            let el = e.currentTarget.querySelector('.row_option');
            let allEl = document.querySelectorAll('.playbook_list .row_option');
            if(el.classList.contains('active')){
                el.classList.remove('active');
            }else{
                for(let i = 0; i < allEl.length; i++){
                    allEl[i].classList.remove('active');
                }
                el.classList.add('active');
            }
        },
        userSetting(e){
            let x = e.target.closest('tr').querySelector('h4').innerHTML;
            let y = e.target.closest('tr').querySelector('.email_wpr').innerHTML;
            this.rowInfo.name = x;
            this.rowInfo.email = y;
            this.tableUser = true;
        },
        itemSetup(el){
            this.sidebarHeader = el;
            this.pageSetup = true;
        },
        mailResend(el){
            this.actionInfo = el;
            this.resendModal = true;
        },
        confirmFunc(el){
            this.confirmInfo = el;
            this.confirmScreen = true;
        },
        folderSet(ev){
            let el = ev.currentTarget;
            let AllEl = el.closest('.folders_list').querySelectorAll('.dropdown');            
            if(el.classList.contains('active')){
                el.classList.remove('active');
            }else{
                for(let i = 0; i < AllEl.length; i++){
                    AllEl[i].classList.remove('active');
                }
                el.classList.add('active');
            }
        },
        folderRename(e){
            let el = e.target.closest('.folder_item').querySelector('.editable');
            el.setAttribute("contenteditable", "true");
            if (el.hasAttribute("contenteditable")) {
                el.style.cssText = `background: #2f7eed; color: #fff;`;
            }
        },
        closeBar(){
            this.pageSetup = false;
            this.shareWorkout = false;
            this.sharePlan = false;
            this.userProfile = false;
            this.tableUser = false;
            this.newPage = false;
        },
        closeSortingDropdown () {
            const vm = this;

            vm.actionList = false;
        },
        togglePerPageFilter () {
            const vm = this;
            vm.actionList         = false;
            vm.bulkActionDropdown = false;
            vm.modeFilterDropdown = false;

            const filter = vm.$refs['workout-per-page-filter'];

            if (filter) {
                filter.dropdown = !filter.dropdown;
            }
        },
        sortingFilter (filter) {
            const vm = this;

            vm.filterTitle     = filter.title;
        },
        closePerPageDropdown () {
            const vm = this;

            const filter = vm.$refs['page-per-page-filter'];

            if (filter) {
                filter.dropdown = false;
            }
        },
        showAction (ev) {
            const vm = this;

            if (ev) {
                vm.showContent = true;
            } else {
                vm.showContent = false;
            }
        }
    }
}
</script>

<style scoped>
.tab_sec{
    max-width: 1200px;
    margin: 0 auto 10px auto;
    border: 0;
}
.tab_sec li.active {
    border-radius: 5px;
}
.progress_container{
    max-width: 1170px;
    padding: 0 20px;
    margin: 0 auto;
}
.playbook_list {
    list-style-type: none;
    margin: 0 -20px;
    display: flex;
    flex-wrap: wrap;
}
.playbook_list > li{
    padding: 20px;
    width: 33.333%;
}
.plybook_item{
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    line-height: 0;
    position: relative;
    height: 100%;
}
.replace_img{
    position: absolute;
    right: 15px;
    top:15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
}
.replace_img img{
    max-width: 16px;
    height: auto;
}
.plybook_item .item_img .checkbox{
    position: absolute;
    left: 15px;
    top: 15px;    
}
.plybook_item .item_img .checkbox span{
    border-color: #8a8a8a;
}
.plybook_item .item_img .checkbox span i{
    color: #2C3E50;
}
.plybook_item .item_img{
    height: 200px;
    position: relative;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
}
.plybook_item .item_img .img{
    height: 200px;
    width: 100%;
    object-fit: cover;
}
.plybook_item .item_img .statistics {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    /* background: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(255,255,255,0.8) 70%, rgba(255,255,255,0.5)); */
    background: #f5f5f5;
    padding: 30px 15px 15px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.plybook_item .item_img .statistics li {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #2C3E50;
    padding: 3px 0;
    text-align: left;
}
.plybook_item .item_img .statistics li:first-child {
    margin-bottom: auto;
}
.plybook_item .img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
}
.plybook_item .item_ttl {
    font-size: 16px;
    line-height: 22px;
    color: #121525;
    font-weight: 400;
    padding: 15px 20px 0 20px;
    margin-bottom: 15px;
    text-align: left;
    display: block;
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.plybook_item .item_info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 20px 20px;
}
.plybook_item .item_info .member{
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.plybook_item .item_info img{
    margin-right: 5px;
    max-height: 16px;
    width: auto;
}
.plybook_item .item_info > ul{
    list-style-type: none;
    display: flex;
    align-items: center;
}
.plybook_item .item_info > ul > li{
    width: 30px;
    height: 30px;
    font-size: 15px;
    color: #2f7eed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #f3f3f3;
    margin-left: 8px;
    cursor: pointer;
    position: relative;
}
.plybook_item .item_info .row_option{
    bottom: -10px;
    top: auto;
    animation: dropMove2 .5s ease-in-out;    
}
.plybook_item .item_info .row_option:before{
    bottom: 18px;
    top: auto;
    left: auto;
    transform: rotate(45deg) scale(1);
}
.plybook_item .item_img:hover .statistics, .plybook_item .item_img.active .statistics{
    opacity: 1;
}
@keyframes dropMove2 {
    from {margin-bottom: 10px; opacity: 0;}
    to {margin-bottom: 1px; opacity: 1;}
}

.share_playbook .modal_container{
    height: 640px;
}
.modal.share_playbook .modal_header{
    padding: 20px 30px 0;
}
.modal.share_playbook .modal_body{
    padding: 0 30px;
    flex-direction: column;
}
.share_playbook .tab_row, .report_section .tab_row{
    padding: 20px 0 0 0;
    display: flex;
}
.share_playbook .tab_row li, .report_section .tab_row li{
    font-size: 13px;
    line-height: 15px;
    padding: 8px 20px;
    position: relative;
    cursor: pointer;
}
.share_playbook .tab_row li.active, .report_section .tab_row li.active{
    background: #eaeaea;
    border-radius: 5px 5px 0 0;
}
.share_playbook .tab_wpr{
    width: 100%;
}
.share_playbook .result_wpr {
    width: 100%;
}
table button{
    padding: 3px 10px;
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
    background: #f2a31e;
    border-radius: 10px;
    color: #fff;
    margin-left: 7px;
}
table button.success{
    background: #00aa14;
}
table button.failed{
    background: #f04438;
}
.modal_footer{
    border-radius: 0 0 12px 12px;
}
.folder_list{
    display: flex;
    margin-top: 20px;
}
.folder_list li{
    width: 33.333%;
}
.sorting_card li{
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}
.sorting_card li img{
    width: 100px;
    height: auto;
    object-fit: cover;
}
.sorting_card .card_details{
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    padding: 10px 15px;
}
.sorting_card .card_details p{
    font-size: 10px;
    line-height: 13px;
    font-weight: 400;
    color: #5a5a5a;
    margin-top: 3px;
}
.sorting_card li:hover{
    background: #fff;
    box-shadow: 0px 0px 44px rgba(0,0,0,0.13);
}

.result_wpr.small .actions > ul{
    height: 35px;
}
.result_wpr.small .actions > ul li, .result_wpr.small .actions > ul li input{
    padding: 7px 10px;
    font-size: 14px !important;
}
.result_wpr.small .actions > ul li .dropdown_wpr{
    width: 100%;
}
.result_wpr.small table td {
    padding: 12px;
    font-size: 13px;
    line-height: 16px;
}
.result_wpr.small table th{
    padding: 7px 12px;
    font-size: 12px;
    line-height: 16px;
}
.result_wpr.small table td .user_img, .result_wpr.small table td .user_img img{
    width: 30px;
    height: 30px;
}
.result_wpr.small table td h4 {
    font-size: 13px;
    line-height: 16px;
    color: #2f7eed;
    font-weight: 500;
}
.result_wpr table td:not(:first-child, :last-child, :nth-child(2)) *{
    margin: 0 auto;
}
.result_wpr table td .score_circle{
    display: inline-block;
}
.result_wpr table td .score_circle .inner_circle .score{
    font-size: 11px;
    line-height: 13px;
}
.result_wpr table td .score_circle .inner_circle .score span{
    font-size: 9px;
    line-height: 11px;
}
.result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child{
    width: auto;
    text-align: left;
    padding-left: 15px;
}
.row_option li a{
    color: inherit;
    text-decoration: none;
}
.action_list{
    display: flex;
    flex-wrap: wrap;
}
.action_list li{
    margin-right: 5px;
    cursor: pointer;
}
.action_list li i{
    color: #2f7eed;
}
.folders_list{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -5px;
}
.folders_list li{
    padding: 0 5px;
}
.folders_list .folder_item{
    border: 1px solid #E9E9E9;
    padding: 10px 15px;
    border-radius: 9px;
    background: #fff;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    line-height: 17px;
    color: #5A5A5A;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    height: 100%;
    cursor: pointer;
}
.folders_list .folder_item img{
    max-width: 30px;
    height: auto;
    margin-right: 10px;
}
.folders_list .folder_item:hover{
    box-shadow: 0px 0px 44px rgba(0,0,0,0.13)
}
.folders_list .folder_item .drp_wrapper{
    display: none;
}
.folders_list .folder_item .dropdown{
    margin-left: 20px;
}
.folders_list .folder_item .dropdown.active .drp_wrapper{
    display: block;
}
.folders_list .folder_item .dropdown.active .drp_wrapper ul{
    padding: 5px 0;
}
.folders_list .folder_item .dropdown.active .drp_wrapper ul li{
    font-size: 10px;
    line-height: 12px;
    padding: 4px 8px;
    text-align: left;
}
.modal.redirect_modal .modal_container{
    height: 420px;
}
.modal.redirect_modal .section_ttl{
    font-size: 16px;
    line-height: 20px;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    width: 100%;
    margin: 0 0 10px;
}
.modal.redirect_modal .section_ttl span{
    padding: 0 10px;
    background: #fff;
}
.modal.redirect_modal .section_ttl:after{
    content: '';
    position: absolute;
    left: 7px;
    right: 7px;
    top: 50%;
    border-bottom: 1px solid #e9e9e9;
    z-index: -1;
}
.redirect_list{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.redirect_list li{
    width: 33.333%;
    padding: 5px 8px;
}
.redirect_list li label h5{
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    margin: 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 10px;
    cursor: pointer;
    background: rgba(242, 163, 29, 0.05);
    border: 1px solid rgba(242, 163, 29, 0.3);
    color: #757575;
    filter: grayscale(1);
}
.redirect_list li label input[type=radio]:checked ~ h5{
    filter: grayscale(0);
    color: #f2a31d;
}
.redirect_action{
    width: 100%;
    padding: 0 8px;
}
.modal.redirect_modal .action_wpr{
    margin-top: auto;
}
.ques_listing li{
    font-size: 14px;
    line-height: 19px;
    list-style-type:decimal;
    margin-left: 17px;
    padding: 10px 0 10px 5px;
    border-bottom: 1px solid #e9e9e9;
}
.preview_area .close_btn{
    right: 15px;
    top: 15px;
    left: auto;
    position: fixed;
    background: #eaeaea;
    width:25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}
.modal .preview_area.show_sidebar{
    left: 0;
    width: 100%;
}
</style>