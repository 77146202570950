<template>
    <div class="inner_pages client_admin_page" :style="`
        --var-portal-tab-background-color: ${clientPortal.portal_tab_bg_color != null ? clientPortal.portal_tab_bg_color : '#2f7fed'};
        --var-portal-tab-text-color: ${clientPortal.portal_tab_text_color != null ? clientPortal.portal_tab_text_color : '#FFF'};
        `">
        <!-- <inner-sidebar /> -->
        <div class="loader_area" v-show="clientPortalLoader"><quote-loader/></div>
        <div class="respective_content" v-show="!clientPortalLoader && user.id && !user.has_portal">
            <div class="text-center mt-5">
                <img :src="require('@/assets/images/sorry-page.svg')" class="w-50" />
                <h2>You don't have permission to access this page; it has been moved to your location account.</h2>
            </div>
        </div>
        <div class="respective_content" v-show="!clientPortalLoader && user.has_portal">
            <div class="section_header">
                <h2>Client Portal</h2>
                <button type="button" class="video_btn ml-3" @click="tutorial = true;"><i class="fas fa-play"></i></button>
                <ul class="ml-auto">
                    <li class="mr-3">
                        <label for="preview_portal" class="switch_option capsule_btn">
                            <h5>Preview Portal</h5>
                            <input type="checkbox" id="preview_portal" v-model="previewPortal" hidden>
                            <div><span></span></div>
                        </label>
                    </li>
                    <li class="mr-2">
                        <label for="preview_login" class="switch_option capsule_btn">
                            <h5>Preview Login</h5>
                            <input type="checkbox" id="preview_login" v-model="previewLogin" hidden>
                            <div><span></span></div>
                        </label>
                    </li>
                    <li @click="portalSetting = true" class="pointer">
                        <button type="button" class="pointer">
                            <!-- <img src="@/assets/images/cog.png" alt=""> -->
                            <i class="fas fa-cog"></i>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="client_card">
                <div class="card_header pointer" @click="headerSetting = true" :style="`background: ${clientPortal.header_bgcolor}; color: ${clientPortal.header_textcolor};`">
                    <nav>
                        <img v-if="clientPortal.logo && clientPortal.logo != null" class="logo_img" :src="clientPortal.logo" alt="logo">
                        <a class="dropdown-item dashboard-menu-text pointer">
                            <h4>Dashboard</h4>
                        </a>
                    </nav>
                    <div class="user_box">
                        <img :src="clientPortal.author ? clientPortal.author : user.profile_pic" :alt="user.full_name">
                        <div class="user_info">
                            <h5 :style="`color: ${clientPortal.header_textcolor};`">{{ user.full_name }}</h5>
                        </div>
                    </div>
                    <button class="header_setting"><i class="far fa-edit"></i></button>
                </div>
                <div class="card_body">
                    <div @click="coverSetting = true" class="pointer w-100" :class="{ 'draft': !form.display_cover , 'cover-image' : form.cover_type == 1 }">
                        <img :src="form.cover ? form.cover : require('@/assets/images/thumb/default-cover.svg')" alt="banner" class="banner_img" v-if="(form.cover_type == 2 || form.cover_type == 3)">
                        <img :src="form.small_cover ? form.small_cover : require('@/assets/images/thumb/default-sm-cover.svg')" alt="banner" class="banner_img tab" v-if="(form.cover_type == 2 || form.cover_type == 3)">
                        <img :src="form.small_cover ? form.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="banner" class="banner_img cell" v-if="(form.cover_type == 2 || form.cover_type == 3)">
                        <span v-if="form.cover_type == 1" class="overlay" :style="`background-color: ${form.cover_background_color ? form.cover_background_color : form.opac_overlay_color};`"></span>
                        <span v-else-if="form.cover_type == 2 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                        <span v-else-if="form.cover_type == 3 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                        <div class="banner_content desk">
                            <h4 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.headline_setting.font_size}px; line-height: ${parseInt(form.headline_setting.font_size) + 20}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.headline_setting.font_color}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                            <h1 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.subheadline_setting.font_size}px; line-height: ${parseInt(form.subheadline_setting.font_size) + 20}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.description }}</h1>
                        </div>
                        <div class="banner_content tab">
                            <h4 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.headline_setting.font_size / 2}px; line-height: ${(parseInt(form.headline_setting.font_size) + 20) / 2}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.headline_setting.font_color}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                            <h1 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.subheadline_setting.font_size / 1.5}px; line-height: ${(parseInt(form.subheadline_setting.font_size) + 20) / 2}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.description }}</h1>
                        </div>
                        <div class="banner_content cell">
                            <h4 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.headline_setting.font_size / 3}px; line-height: ${(parseInt(form.headline_setting.font_size) + 20) / 3}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.headline_setting.font_color}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                            <h1 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.subheadline_setting.font_size / 2}px; line-height: ${(parseInt(form.subheadline_setting.font_size) + 20) / 2}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.description }}</h1>
                        </div>
                    </div>
                    <image-library v-model="coverImage" :is-button="true" image-type="public-cover" upload-text="Cover" />
                    <div class="action">
                        <label for="active_banner" class="switch_option capsule_btn">
                            <h5 class="mr-2">{{ form.display_cover ? 'Active' : 'Inactive' }}</h5>
                            <input type="checkbox" id="active_banner" :true-value="1" :false-value="0" v-model="form.display_cover" @change="updatePortal({ display_cover: form.display_cover })" hidden>
                            <div><span></span></div>
                        </label>
                        <i class="far fa-edit" @click="coverSetting = true"></i>
                    </div>
                </div>
            </div>
            <!-- <div class="dash_tabs">
                <ul class="tab_btn">
                    <li @click="navigationTab = 'dashboard'" :class="{ active: navigationTab === 'dashboard' }"><i class="fas fa-home"></i></li>
                    <li v-if="clientPortal.playbook_tab" @click="navigationTab = 'playbook'" :class="{ active: navigationTab === 'playbook' }">Playbooks</li>
                    <li v-if="clientPortal.page_tab" @click="navigationTab = 'page'" :class="{ active: navigationTab === 'page' }">Pages</li>
                    <li v-if="clientPortal.form_tab" @click="navigationTab = 'form'" :class="{ active: navigationTab === 'form' }">Forms</li>
                    <li v-if="clientPortal.workout_tab" @click="navigationTab = 'workout'" :class="{ active: navigationTab === 'workout' }">Workouts</li>
                    <li v-if="clientPortal.progress_tab" @click="navigationTab = 'progress'" :class="{ active: navigationTab === 'progress' }">Progress</li>
                </ul>
            </div> -->
            <div class="dash_tabs">
                <ul class="tab_btn">
                    <li @click="navigationTab = 'dashboard'" :class="{ active: navigationTab === 'dashboard' }"><i class="fas fa-home"></i></li>
                    <li v-if="clientPortal.playbook_tab || clientPortal.page_tab" @click="navigationTab = 'classroom'" :class="{ active: navigationTab === 'classroom' }">Classroom</li>
                    <li @click="navigationTab = 'calendar'" :class="{ active: navigationTab === 'calendar' }">Calendar</li>
                    <li v-if="clientPortal.form_tab" @click="navigationTab = 'form'" :class="{ active: navigationTab === 'form' }">Forms</li>
                    <li v-if="clientPortal.progress_tab" @click="navigationTab = 'charts'" :class="{ active: navigationTab === 'charts' }">Charts</li>
                </ul>
            </div>
            <div class="dashboard_content" v-if="navigationTab === 'dashboard'">
                <div class="full_width">
                    <div class="section_item">
                        <h3>
                            <span :style="`color: ${clientPortal.announcement_title_text_color}`">Your Activity</span>
                            <label for="activity-widget" class="switch_option capsule_btn border-0 p-0">
                                <input type="checkbox" id="activity-widget" :true-value="1" :false-value="0" v-model="form.activity_widget" @change="updatePortal({ activity_widget: form.activity_widget })" hidden>
                                <div><span></span></div>
                            </label>
                        </h3>
                        <ul class="activity_score" v-if="form.activity_widget  && (clientPortal.has_habit_score || clientPortal.has_progress_score || clientPortal.has_portal_login || clientPortal.has_referrals)">
                            <li v-if="clientPortal.has_habit_score">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score">92<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="80" height="80">
                                        <circle cx="40" cy="40" r="37" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="clientPortal.habit_score_color" :stroke-dasharray="207"/>
                                    </svg>
                                </div>
                                <h5>Habit Score</h5>
                            </li>
                            <li v-if="clientPortal.has_progress_score">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score">64<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="80" height="80">
                                        <circle cx="40" cy="40" r="37" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="clientPortal.progress_score_color" :stroke-dasharray="127"/>
                                    </svg>
                                </div>
                                <h5>Progress Score</h5>
                            </li>
                            <li v-if="clientPortal.has_portal_login">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score">76</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="80" height="80">
                                        <circle cx="40" cy="40" r="37" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="clientPortal.login_score_color" :stroke-dasharray="141"/>
                                    </svg>
                                </div>
                                <h5>Playbook Logins</h5>
                            </li>
                            <li v-if="clientPortal.has_referrals">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score">105</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="80" height="80">
                                        <circle cx="40" cy="40" r="37" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="clientPortal.referral_score_color" :stroke-dasharray="115"/>
                                    </svg>
                                </div>
                                <h5>Referrals</h5>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="left_side">
                    <div class="section_item">
                        <h3>
                            <span :style="`color: ${clientPortal.announcement_title_text_color}`">{{ clientPortal.welcome_video_title ? clientPortal.welcome_video_title : 'Welcome' }}</span>
                            <label for="welcome-widget" class="switch_option capsule_btn border-0 p-0">
                                <input type="checkbox" id="welcome-widget" :true-value="1" :false-value="0" v-model="form.has_welcome_video" @change="updatePortal({ has_welcome_video: form.has_welcome_video })" hidden>
                                <div><span></span></div>
                            </label>
                        </h3>
                        <div :class="{'draft' : !form.has_welcome_video}">
                            <div class="video_wpr" @click="showWelcomeVideo = true" v-if="(clientPortal.welcome_video_type == 1 && clientPortal.welcome_video_link) || (clientPortal.welcome_video_type == 2 && clientPortal.welcome_video_embed_code)">
                                <div class="video-overlay" @click="showWelcomeVideo = true"></div>
                                <div v-html="clientPortal.welcome_video_source"></div>
                            </div>
                            <img src="@/assets/images/thumb/video-placeholder.svg" alt=""  v-if="(clientPortal.welcome_video_type == 1 && !clientPortal.welcome_video_link) || (clientPortal.welcome_video_type == 2 && !clientPortal.welcome_video_embed_code)">
                        </div>
                    </div>
                </div>
                <div class="right_side">
                    <div class="section_item">
                        <h3>
                            <span :style="`color: ${clientPortal.announcement_title_text_color ? clientPortal.announcement_title_text_color : '#000'};`">{{ clientPortal.announcement_title ? clientPortal.announcement_title : 'New Announcement' }}</span>
                            <label for="announcement-widget" class="switch_option capsule_btn border-0 p-0">
                                <input type="checkbox" id="announcement-widget" :true-value="1" :false-value="0" v-model="form.has_announcement" @change="updatePortal({ has_announcement: form.has_announcement })" hidden>
                                <div><span></span></div>
                            </label>
                        </h3>
                        <div class="extra_info" :style="`background: ${clientPortal.announcement_bg_color}; padding: ${clientPortal.has_announcement_link == 0 ? '18px 10px' : ''};`" :class="{'draft' : !form.has_announcement}">
                            <span :style="`color: ${clientPortal.announcement_text_color ? clientPortal.announcement_text_color : '#000'};`">{{ clientPortal.announcement_message }}</span>
                            <div class="action_wpr mt-2" v-if="clientPortal.has_announcement_link">
                                <h6>
                                    <a :href="clientPortal.announcement_link" target="_blank" :style="`color: ${clientPortal.announcement_link_color}`">{{ clientPortal.announcement_link_text }}</a>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="full_width">
                    <div class="section_item">
                        <h3 class="mb-1">
                            <span :style="`color: ${clientPortal.announcement_title_text_color}`">Pinned Training</span>
                            <label for="pinned-widget" class="switch_option capsule_btn border-0 p-0">
                                <input type="checkbox" id="pinned-widget" :true-value="1" :false-value="0" v-model="form.pinned_widget" @change="updatePortal({ pinned_widget: form.pinned_widget })" hidden>
                                <div><span></span></div>
                            </label>
                        </h3>
                        <div class="sliderWpr" v-if="form.pinned_widget">
                            <ul class="client_productList">
                                <li v-for="(playbook, p) of pinnedPlaybooks" :key="p">
                                    <div class="product_card">
                                        <img v-if="playbook.dashboard_thumb" :src="playbook.dashboard_thumb" alt="" class="product_thumb">
                                        <img v-else src="@/assets/images/thumb/default-playbook.svg" alt="" class="product_thumb">
                                        <div class="product_cont playbook-desc">
                                            <h4>{{ playbook.title.length > 35 ? playbook.title.substr(0, 35)+'...' : playbook.title }}</h4>
                                            <p>{{ playbook.description && playbook.description.length > 70 ? playbook.description.substr(0, 70)+'...' : playbook.description }}</p>
                                            <a href="javascript:void(0);" class="primary_btn custom_element">Open Playbook</a>
                                        </div>
                                        <button type="button" class="pin custom-pin"><i class="fas fa-thumbtack"></i></button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard_content" v-if="navigationTab == 'classroom'">
                <div class="full_width">
                    <div class="portal_forms">
                        <div class="result_wpr new">
                            <div class="actions mt-2">
                                <ul>
                                    <li class="filter_btn">
                                        <div v-if="clientPortal.playbook_tab" class="btn_item pointer" :class="classroomTab === 'playbooks' ? 'active' : ''" @click="classroomTab = 'playbooks'">Playbooks</div>
                                        <div v-if="clientPortal.page_tab" class="btn_item pointer" :class="classroomTab === 'page' ? 'active' : ''" @click="classroomTab = 'page'">Pages</div>
                                    </li>
                                    <li class="optionDrops search_area" :class="{'active': searchField}" v-if="classroomTab === 'playbooks'">
                                        <input type="text" placeholder="Search" :disabled="assetsLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                                        <button class="search_btn" @click="searchField = !searchField;">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </li>
                                    <li class="optionDrops contacts-tabs" @click="filterList = !filterList" v-click-outside="closeFilters" v-if="classroomTab === 'playbooks'">
                                        Filter<i class="fas fa-angle-down"></i>
                                        <div class="dropdown_wpr" :class="filterList ? 'active' : ''">
                                            <ul>
                                                <li v-for="(category, c) of playbookCategories" :key="c" @click="handleCategoryFilter(category.id)">{{ category.name }}</li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <playbook-component v-if="classroomTab === 'playbooks'"/>
                        <page-component v-if="classroomTab === 'page'"/>
                    </div>
                </div>
            </div>
            <!-- <div class="dashboard_content" v-if="navigationTab == 'page'">
                <div class="full_width">
                    <page-component />
                </div>
            </div> -->
            <div class="dashboard_content" v-if="navigationTab == 'calendar'">
                <div class="full_width event_calendar">
                    <!-- <button class="show_hide" @click="show_calendar = !show_calendar">
                        {{ show_calendar ? "Hide" : "Show" }} Calendar
                    </button> -->
                    <FullCalendar class="fullCalendar" :options='calendarOptions' v-show="show_calendar"></FullCalendar>
                </div>
            </div>
            <div class="dashboard_content" v-if="navigationTab == 'form'">
                <div class="full_width">
                    <form-component />
                </div>
            </div>
            <div class="dashboard_content" v-if="navigationTab == 'charts'">
                <div class="full_width">
                    <progress-component />
                </div>
            </div>
            <div class="dashboard_content" v-if="navigationTab == 'workout'">
                <div class="full_width">
                    <workout-component />
                </div>
            </div>
            <footer class="footer-content">
                <div class="step_action">
                    <label for="footercard" class="switch_option capsule_btn border-0 p-0 ml-auto">
                        <input type="checkbox" id="footercard" :true-value="1" :false-value="0" v-model="form.display_footer" @change="updatePortal({ display_footer: form.display_footer })" hidden>
                        <div><span></span></div>
                    </label>
                    <!-- <img src="@/assets/images/cog.png" alt="" class="ml-3 pointer" @click="coachSetup = true"> -->
                    <button type="button" class="edit" @click="footerSetting = true"><i class="far fa-edit"></i></button>
                </div>
                <div class="dashboard_footer" :class="{'draft' : !form.display_footer}" :style="`background: ${clientPortal.footer_bgcolor}`">
                    <ul>
                        <li class="pointer" v-if="clientPortal.has_facebook"><a :href="clientPortal.footer_facebook" :style="`border-color: ${clientPortal.footer_textcolor}; color: ${clientPortal.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                        <li class="pointer" v-if="clientPortal.has_twitter"><a :href="clientPortal.footer_twitter" :style="`border-color: ${clientPortal.footer_textcolor}; color: ${clientPortal.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                        <li class="pointer" v-if="clientPortal.has_instagram"><a :href="clientPortal.footer_instagram" :style="`border-color: ${clientPortal.footer_textcolor}; color: ${clientPortal.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                    <h4 :style="`color: ${clientPortal.footer_textcolor};`">{{ form.footer_company }}</h4>
                    <p>
                        <a :href="clientPortal.footer_terms" :style="`color: ${clientPortal.footer_textcolor};`">Terms</a>
                        <a :href="clientPortal.footer_policy" :style="`color: ${clientPortal.footer_textcolor};`">Privacy</a>
                        <span v-html="form.white_label"></span>
                    </p>
                </div>
            </footer>
            <button type="button" v-if="clientPortal.has_chatbox" class="chat_btn pointer" :class="clientPortal.chat_placement" @click="chat = true" :style="`background: ${clientPortal.chat_button_bg_color}; color: ${clientPortal.chat_button_text_color}; ${clientPortal.chat_placement}: ${ clientPortal.chat_placement == 'left' ? 50 : 50 }px;`">
                <i class="fas fa-comments"></i>
            </button>
            <div class="chatbox_wpr" :class="clientPortal.chat_placement" v-if="chat" :style="`background: ${clientPortal.chat_background_color}; ${clientPortal.chat_placement}: ${ clientPortal.chat_placement == 'left' ? 50 : 50 }px;`">
                <button type="button" class="close_btn" @click="chat = false"><i class="fas fa-times"></i></button>
                <chat :setting="clientPortal" :color="clientPortal.chat_button_bg_color"/>
            </div>
        </div>
        <setting-component v-model="portalSetting" />
        <header-setting v-model="headerSetting" />
        <cover-setting v-model="coverSetting" />
        <footer-setting v-model="footerSetting" />
        <login-preview v-model="previewLogin" />
        <portal-preview v-model="previewPortal" />
        <div class="modal video_player" v-if="showWelcomeVideo" :class="{ active : showWelcomeVideo }" @click.self="showWelcomeVideo = false">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="showWelcomeVideo = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal_body p-0" style="margin-right: -4px;">
                    <div class="video_wpr" v-html="clientPortal.welcome_video_source"></div>
                </div>
            </div>
        </div>
        <div class="modal secondary video_player" v-if="tutorial">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <iframe src="https://www.loom.com/embed/d0aa8c4bc7534b70a8728805c2a2424e?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
                <div class="modal_footer">
                    <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
        <div class="modal workout_modal" v-if="workout_details" :class="{active : workout_details}">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>Activity <span>{{selected_date}}</span></h1>
                    </div>
                    <button class="close_btn left_out" @click="workout_details = false" v-if="!see_plan"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <!-- <div class="setting_wpr workout_list" v-if="ev_type == 'workout'"> -->
                    <div class="setting_wpr py-3" v-if="completed_view">
                        <h3 class="sub_heading2 py-1 m-0 rotate" @click="toggleList($event)">Workouts <i class="fas fa-caret-down"></i></h3>
                        <ul class="workout_card workout_list show">
                            <li>
                                <div class="card_wrapper">
                                    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                        <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">Week 1 Foundation - Leg & Core<a @click.self="see_plan = true;">See Plan</a><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_title">Warm-up
                                                    <h6>
                                                        <span>3 steps</span>
                                                        <span>12 reps</span>
                                                        <span>Bodyweight</span>
                                                    </h6>
                                                </div>
                                                <div class="block_item border-0">
                                                    <div class="details_info p-0">
                                                        <h5>Squat</h5>
                                                        <table class="task_history">
                                                            <thead>
                                                                <tr>
                                                                    <th>Work Time</th>
                                                                    <th>Load</th>
                                                                    <th>Rest Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>01 : 00</td>
                                                                    <td>Bodyweight</td>
                                                                    <td>00 : 20</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="block_item border-0">
                                                    <div class="details_info p-0">
                                                        <h5>Resistance Band Lateral Walk</h5>
                                                        <table class="task_history">
                                                            <thead>
                                                                <tr>
                                                                    <th>Work Time</th>
                                                                    <th>Load</th>
                                                                    <th>Rest Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>01 : 00</td>
                                                                    <td>Bodyweight</td>
                                                                    <td>00 : 20</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="block_item border-0">
                                                    <div class="details_info p-0">
                                                        <h5>Medicine Ball Lunge with Biceps Curl</h5>
                                                        <table class="task_history">
                                                            <thead>
                                                                <tr>
                                                                    <th>Work Time</th>
                                                                    <th>Load</th>
                                                                    <th>Rest Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>01 : 00</td>
                                                                    <td>10 lbs</td>
                                                                    <td>00 : 20</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="card_wrapper">
                                    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1832 19.8168C18.8702 21.1298 18.8702 23.2585 20.1832 24.5714L28.6119 33.0001L20.1833 41.4287C18.8703 42.7416 18.8703 44.8703 20.1833 46.1833C21.4962 47.4962 23.6249 47.4962 24.9379 46.1833L33.3665 37.7547L41.795 46.1833C43.108 47.4962 45.2367 47.4962 46.5496 46.1833C47.8626 44.8703 47.8626 42.7416 46.5497 41.4287L38.1211 33.0001L46.5497 24.5714C47.8627 23.2585 47.8627 21.1298 46.5497 19.8168C45.2368 18.5039 43.1081 18.5039 41.7951 19.8168L33.3665 28.2455L24.9378 19.8168C23.6249 18.5039 21.4961 18.5039 20.1832 19.8168Z" fill="#EC4242"/>
                                        <circle cx="33" cy="33" r="32" stroke="#EC4242" stroke-width="2"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">Week 1 Foundation - Pull<a @click.self="see_plan = true;">See Plan</a><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_title">Pull</div>
                                                <div class="block_item">
                                                    <div class="video_area">
                                                        <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Hamstring/Gracilis/Pull-up--neutral-grip-_Back.gif" at="Pull Up Overhand Grip">
                                                    </div>
                                                    <div class="details_info">
                                                        <h5>Pull Up Overhand Grip</h5>
                                                        <h6>
                                                            <span>5 sets</span>
                                                            <span>5 reps</span>
                                                            <span>Bodyweight</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="block_item">
                                                    <div class="video_area">
                                                        <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Traps/Lower-Trapezius---Completed/Cable-Reverse-Grip-Lat-Pulldown-Seated.gif" at="Cable Reverse Grip Lat Pulldown Seated">
                                                    </div>
                                                    <div class="details_info">
                                                        <h5>Cable Reverse Grip Lat Pulldown Seated</h5>
                                                        <h6>
                                                            <span class="v_tag">V</span>
                                                            <span>4 sets</span>
                                                            <span>12 reps</span>
                                                            <span>60% of 1rm</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="block_item">
                                                    <div class="video_area">
                                                        <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Rotator-Cuff/Teres-Minor/Lever-T-bar-Row--plate-loaded-.gif" at="Lever T bar Row (plate loaded)">
                                                    </div>
                                                    <div class="details_info">
                                                        <h5>Lever T bar Row (plate loaded)</h5>
                                                        <h6>
                                                            <span class="v_tag">V</span>
                                                            <span>4 sets</span>
                                                            <span>12 reps</span>
                                                            <span>60% of 1rm</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="block_item">
                                                    <div class="video_area">
                                                        <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Hamstring/Hamstrings/Dumbbell-Romanian-Deadlift_Hips.gif" at="Dumbbell Romanian Deadlift Hips">
                                                    </div>
                                                    <div class="details_info">
                                                        <h5>Dumbbell Romanian Deadlift Hips</h5>
                                                        <h6>
                                                            <span>3 sets</span>
                                                            <span>12 reps</span>
                                                            <span>60% of 1rm</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="block_item">
                                                    <div class="video_area">
                                                        <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Glutes/Gluteus-Maximus/Twist-Hip-Lift.gif" at="Twist Hip Lift">
                                                    </div>
                                                    <div class="details_info">
                                                        <h5>Twist Hip Lift</h5>
                                                        <h6>
                                                            <span>3 sets</span>
                                                            <span>12 reps</span>
                                                            <span>Bodyweight</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="view_plan" :class="{'show' : see_plan}">
                            <div class="header">
                                <button class="close_btn" @click="see_plan = false;"><i class="fas fa-chevron-down"></i></button>
                                <h2>View Plan</h2>
                            </div>
                            <div class="cell">
                                <div class="content_area">
                                    <PlanView :clr="{accent_clr : '#2f7eed', timer_clr : '#f2a31d', btn_clr : '#2f7eed', btn_txt_clr : '#fff', block_bg_clr: '#eff5ff'}" />
                                </div>
                            </div>
                        </div>
                        <h3 class="sub_heading2 py-1 m-0 rotate" @click="toggleList($event)">Habits <i class="fas fa-caret-down"></i></h3>
                        <ul class="workout_card show">
                            <li>
                                <div class="card_wrapper">
                                    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                        <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">6 Week Habits<span class="tag">Ongoing</span><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_title">Hydration</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label><div class="task_ans">I drank my snake juice <span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span></div></li>
                                                    <li><label>2</label><div class="task_ans">I drank one gallon of water today <span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></li>
                                                </ol>
                                                <div class="block_title">Strength</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label><div class="task_ans">I completed 100 body weight squats today <span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span></div></li>
                                                    <li><label>2</label><div class="task_ans">I did 100 pushups <span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span></div></li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="card_wrapper">
                                    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1832 19.8168C18.8702 21.1298 18.8702 23.2585 20.1832 24.5714L28.6119 33.0001L20.1833 41.4287C18.8703 42.7416 18.8703 44.8703 20.1833 46.1833C21.4962 47.4962 23.6249 47.4962 24.9379 46.1833L33.3665 37.7547L41.795 46.1833C43.108 47.4962 45.2367 47.4962 46.5496 46.1833C47.8626 44.8703 47.8626 42.7416 46.5497 41.4287L38.1211 33.0001L46.5497 24.5714C47.8627 23.2585 47.8627 21.1298 46.5497 19.8168C45.2368 18.5039 43.1081 18.5039 41.7951 19.8168L33.3665 28.2455L24.9378 19.8168C23.6249 18.5039 21.4961 18.5039 20.1832 19.8168Z" fill="#EC4242"/>
                                        <circle cx="33" cy="33" r="32" stroke="#EC4242" stroke-width="2"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">6 Week Habits<span class="tag">Ongoing</span><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_title">Hydration</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label><div class="task_ans">I drank my snake juice <div class="yes_no_box disabled"><span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span><span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></div></li>
                                                    <li><label>2</label><div class="task_ans">I drank one gallon of water today <div class="yes_no_box disabled"><span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span><span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></div></li>
                                                </ol>
                                                <div class="block_title">Strength</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label><div class="task_ans">I completed 100 body weight squats today <div class="yes_no_box disabled"><span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span><span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></div></li>
                                                    <li><label>2</label><div class="task_ans">I did 100 pushups <div class="yes_no_box disabled"><span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span><span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></div></li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <h3 class="sub_heading2 py-1 m-0 rotate" @click="toggleList($event)">Progress Check-ins<i class="fas fa-caret-down"></i></h3>
                        <ul class="workout_card show">
                            <li>
                                <div class="card_wrapper">
                                    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                        <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">28 Day Makeover <span class="tag">Until June 30, 2023</span><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_title">Progress Questions</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label><div class="task_ans">What is your weight today?<span class="ans_text">120 Pounds</span></div></li>
                                                    <li><label>2</label><div class="task_ans">What is your body fat percentage today?<span class="ans_text">25%</span></div></li>
                                                </ol>
                                                <div class="block_title">Strength</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label><div class="task_ans">How many squats would you like to be able to do in a day?<span class="ans_text">50</span></div></li>
                                                    <li><label>2</label><div class="task_ans">How many pushups would you like to be able to do in one minute?<span class="ans_text">10</span></div></li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="card_wrapper">
                                    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1832 19.8168C18.8702 21.1298 18.8702 23.2585 20.1832 24.5714L28.6119 33.0001L20.1833 41.4287C18.8703 42.7416 18.8703 44.8703 20.1833 46.1833C21.4962 47.4962 23.6249 47.4962 24.9379 46.1833L33.3665 37.7547L41.795 46.1833C43.108 47.4962 45.2367 47.4962 46.5496 46.1833C47.8626 44.8703 47.8626 42.7416 46.5497 41.4287L38.1211 33.0001L46.5497 24.5714C47.8627 23.2585 47.8627 21.1298 46.5497 19.8168C45.2368 18.5039 43.1081 18.5039 41.7951 19.8168L33.3665 28.2455L24.9378 19.8168C23.6249 18.5039 21.4961 18.5039 20.1832 19.8168Z" fill="#EC4242"/>
                                        <circle cx="33" cy="33" r="32" stroke="#EC4242" stroke-width="2"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">28 Day Makeover <span class="tag">Until June 30, 2023</span><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_title">Progress Questions</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label><div class="task_ans">What is your weight today?<span class="ans_text">Not Performed</span></div></li>
                                                    <li><label>2</label><div class="task_ans">What is your body fat percentage today?<span class="ans_text">Not Performed</span></div></li>
                                                </ol>
                                                <div class="block_title">Strength</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label><div class="task_ans">How many squats would you like to be able to do in a day?<span class="ans_text">Not Performed</span></div></li>
                                                    <li><label>2</label><div class="task_ans">How many pushups would you like to be able to do in one minute?<span class="ans_text">Not Performed</span></div></li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <h3 class="sub_heading2 py-1 m-0 rotate" @click="toggleList($event)">Events <i class="fas fa-caret-down"></i></h3>
                        <ul class="workout_card show">
                            <li>
                                <div class="card_wrapper">
                                    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                        <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">Discover the Possibilities <i class="fas fa-chevron-down"></i></h4>
                                        <div class="details booking_details">
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Time</h5>
                                                    <h6>04:30pm - 05:00pm</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Duration</h5>
                                                    <h6>30 min.</h6>
                                                </div>
                                            </div>
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Timezone</h5>
                                                    <h6>Atlantic New York</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Coach Name</h5>
                                                    <h6>Joe Trainer</h6>
                                                </div>
                                            </div>
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Name</h5>
                                                    <h6>Robert Bacins</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Email</h5>
                                                    <h6>robert@gmail.com</h6>
                                                </div>
                                            </div>
                                            <div class="additional_info">
                                                <h5>Asked question</h5>
                                                <p>Etiam egestas fringilla dolor vel aliquam. Morbi vel est fermentum quam laoreet facilisis.</p>
                                            </div>
                                            <div class="additional_info">
                                                <h5>Event location</h5>
                                                <h6>In-person :</h6>
                                                <div class="location_details">
                                                    <div class="location_fld">
                                                        <input type="text" value="123 Super Street Supertown, CO 12345">
                                                        <a class="suffix" @click="show_map = true;"><img src="@/assets/images/icon2.svg" alt=""></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="action_btns">
                                                <a><i class="fas fa-times"></i>Mark Incomplete</a>
                                                <a>Mark as no-show</a>
                                                <a><i class="fas fa-arrow-down"></i>Download Record</a>
                                                <a><i class="fas fa-sync"></i>Reschedule</a>
                                                <a><i class="fas fa-undo"></i>Refund</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="card_wrapper">
                                    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1832 19.8168C18.8702 21.1298 18.8702 23.2585 20.1832 24.5714L28.6119 33.0001L20.1833 41.4287C18.8703 42.7416 18.8703 44.8703 20.1833 46.1833C21.4962 47.4962 23.6249 47.4962 24.9379 46.1833L33.3665 37.7547L41.795 46.1833C43.108 47.4962 45.2367 47.4962 46.5496 46.1833C47.8626 44.8703 47.8626 42.7416 46.5497 41.4287L38.1211 33.0001L46.5497 24.5714C47.8627 23.2585 47.8627 21.1298 46.5497 19.8168C45.2368 18.5039 43.1081 18.5039 41.7951 19.8168L33.3665 28.2455L24.9378 19.8168C23.6249 18.5039 21.4961 18.5039 20.1832 19.8168Z" fill="#EC4242"/>
                                        <circle cx="33" cy="33" r="32" stroke="#EC4242" stroke-width="2"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">Summer Shred Challenge! <i class="fas fa-chevron-down"></i></h4>
                                        <div class="details booking_details">
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Time</h5>
                                                    <h6>04:30pm - 05:00pm</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Duration</h5>
                                                    <h6>30 min.</h6>
                                                </div>
                                            </div>
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Timezone</h5>
                                                    <h6>Atlantic New York</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Coach Name</h5>
                                                    <h6>Joe Trainer</h6>
                                                </div>
                                            </div>
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Name</h5>
                                                    <h6>Robert Bacins</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Email</h5>
                                                    <h6>robert@gmail.com</h6>
                                                </div>
                                            </div>
                                            <div class="additional_info">
                                                <h5>Asked question</h5>
                                                <p>Etiam egestas fringilla dolor vel aliquam. Morbi vel est fermentum quam laoreet facilisis.</p>
                                            </div>
                                            <div class="additional_info">
                                                <h5>Event location</h5>
                                                <h6>In-person :</h6>
                                                <div class="location_details">
                                                    <div class="location_fld">
                                                        <input type="text" value="123 Super Street Supertown, CO 12345">
                                                        <a class="suffix" @click="show_map = true;"><img src="@/assets/images/icon2.svg" alt=""></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="action_btns">
                                                <a><i class="fas fa-sync"></i>Reschedule</a>
                                                <a class="delete_btn">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="setting_wpr py-3" v-else>
                        <h3 class="sub_heading2 py-1 m-0 rotate" @click="toggleList($event)">Workouts <i class="fas fa-caret-down"></i></h3>
                        <ul class="workout_card workout_list show">
                            <li>
                                <div class="card_wrapper">
                                    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                        <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">Week 1 Foundation - Leg & Core<a @click.self="see_plan = true;">See Plan</a><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_title">Warm-up
                                                    <h6>
                                                        <span>3 steps</span>
                                                        <span>12 reps</span>
                                                        <span>Bodyweight</span>
                                                    </h6>
                                                </div>
                                                <div class="block_item border-0">
                                                    <div class="details_info p-0">
                                                        <h5>Squat</h5>
                                                        <table class="task_history">
                                                            <thead>
                                                                <tr>
                                                                    <th>Work Time</th>
                                                                    <th>Load</th>
                                                                    <th>Rest Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>01 : 00</td>
                                                                    <td>Bodyweight</td>
                                                                    <td>00 : 20</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="block_item border-0">
                                                    <div class="details_info p-0">
                                                        <h5>Resistance Band Lateral Walk</h5>
                                                        <table class="task_history">
                                                            <thead>
                                                                <tr>
                                                                    <th>Work Time</th>
                                                                    <th>Load</th>
                                                                    <th>Rest Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>01 : 00</td>
                                                                    <td>Bodyweight</td>
                                                                    <td>00 : 20</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="block_item border-0">
                                                    <div class="details_info p-0">
                                                        <h5>Medicine Ball Lunge with Biceps Curl</h5>
                                                        <table class="task_history">
                                                            <thead>
                                                                <tr>
                                                                    <th>Work Time</th>
                                                                    <th>Load</th>
                                                                    <th>Rest Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>01 : 00</td>
                                                                    <td>10 lbs</td>
                                                                    <td>00 : 20</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="card_wrapper">
                                    <svg width="80" height="93" viewBox="0 0 80 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" stroke="#999999" stroke-width="5"/>
                                        <path d="M17.8241 12.762C12.3284 15.751 11.2758 21.319 11.4365 23.7295C11.7981 27.9477 15.5342 31.0813 16.9805 32.166C18.1375 33.0337 19.7123 32.5275 20.3551 32.166C26.8632 28.6708 31.5636 27.4656 41.0848 27.3451C50.6059 27.2246 57.9578 31.6839 59.5245 32.407C60.778 32.9855 61.7341 32.648 62.0555 32.407C66.4907 29.4181 67.8405 25.3766 67.961 23.7295C68.5636 15.6545 60.7297 12.4005 60.0066 12.1594C59.4281 11.9666 57.8372 11.838 57.1141 11.7979C47.7134 11.1952 22.886 11.7979 21.0782 11.7979C19.632 11.7979 18.3062 12.4406 17.8241 12.762Z" stroke="#999999" stroke-width="5"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">Week 1 Foundation - Pull<a @click.self="see_plan = true;">See Plan</a><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_title">Pull</div>
                                                <div class="block_item">
                                                    <div class="video_area">
                                                        <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Hamstring/Gracilis/Pull-up--neutral-grip-_Back.gif" at="Pull Up Overhand Grip">
                                                    </div>
                                                    <div class="details_info">
                                                        <h5>Pull Up Overhand Grip</h5>
                                                        <h6>
                                                            <span>5 sets</span>
                                                            <span>5 reps</span>
                                                            <span>Bodyweight</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="block_item">
                                                    <div class="video_area">
                                                        <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Traps/Lower-Trapezius---Completed/Cable-Reverse-Grip-Lat-Pulldown-Seated.gif" at="Cable Reverse Grip Lat Pulldown Seated">
                                                    </div>
                                                    <div class="details_info">
                                                        <h5>Cable Reverse Grip Lat Pulldown Seated</h5>
                                                        <h6>
                                                            <span class="v_tag">V</span>
                                                            <span>4 sets</span>
                                                            <span>12 reps</span>
                                                            <span>60% of 1rm</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="block_item">
                                                    <div class="video_area">
                                                        <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Rotator-Cuff/Teres-Minor/Lever-T-bar-Row--plate-loaded-.gif" at="Lever T bar Row (plate loaded)">
                                                    </div>
                                                    <div class="details_info">
                                                        <h5>Lever T bar Row (plate loaded)</h5>
                                                        <h6>
                                                            <span class="v_tag">V</span>
                                                            <span>4 sets</span>
                                                            <span>12 reps</span>
                                                            <span>60% of 1rm</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="block_item">
                                                    <div class="video_area">
                                                        <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Hamstring/Hamstrings/Dumbbell-Romanian-Deadlift_Hips.gif" at="Dumbbell Romanian Deadlift Hips">
                                                    </div>
                                                    <div class="details_info">
                                                        <h5>Dumbbell Romanian Deadlift Hips</h5>
                                                        <h6>
                                                            <span>3 sets</span>
                                                            <span>12 reps</span>
                                                            <span>60% of 1rm</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="block_item">
                                                    <div class="video_area">
                                                        <img src="https://storage.googleapis.com/thrivecoach/workout-gif/Glutes/Gluteus-Maximus/Twist-Hip-Lift.gif" at="Twist Hip Lift">
                                                    </div>
                                                    <div class="details_info">
                                                        <h5>Twist Hip Lift</h5>
                                                        <h6>
                                                            <span>3 sets</span>
                                                            <span>12 reps</span>
                                                            <span>Bodyweight</span>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <a class="redirect_btn" href="https://reachvine.com/PFwTCLYx" target="_blank">Ready to perform<i class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="view_plan" :class="{'show' : see_plan}">
                            <div class="header">
                                <button class="close_btn" @click="see_plan = false;"><i class="fas fa-chevron-down"></i></button>
                                <h2>View Plan</h2>
                            </div>
                            <div class="cell">
                                <div class="content_area">
                                    <PlanView :clr="{accent_clr : '#2f7eed', timer_clr : '#f2a31d', btn_clr : '#2f7eed', btn_txt_clr : '#fff', block_bg_clr: '#eff5ff'}" />
                                </div>
                            </div>
                        </div>
                        <!-- <div class="setting_wpr py-2" v-if="ev_type == 'habit' || ev_type == 'progress'"> -->
                        <h3 class="sub_heading2 py-1 m-0 rotate" @click="toggleList($event)">Habits <i class="fas fa-caret-down"></i></h3>
                        <ul class="workout_card show">
                            <li>
                                <div class="card_wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#999999" d="M394.633,220.663,269.475,25.174a16,16,0,0,0-26.95,0L117.364,220.665A170.531,170.531,0,0,0,84.1,322.3c0,94.785,77.113,171.9,171.9,171.9s171.9-77.113,171.9-171.9A170.519,170.519,0,0,0,394.633,220.663ZM256,462.2c-77.14,0-139.9-62.758-139.9-139.9a138.758,138.758,0,0,1,27.321-83.058q.319-.432.608-.884L256,63.475,367.967,238.359q.288.453.608.884A138.754,138.754,0,0,1,395.9,322.3C395.9,399.441,333.14,462.2,256,462.2Z"/></svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">4 Week Habits<span class="tag">Ongoing</span><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_title">Hydration</div>
                                                <ul class="todo_list">
                                                    <li><label>1</label><div class="task_ans">I drank my snake juice<div class="yes_no_box"><span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span><span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></div></li>
                                                    <li><label>2</label><div class="task_ans">I drank one gallon of water today<div class="yes_no_box"><span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span><span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></div></li>
                                                </ul>
                                                <div class="block_title">Strength</div>
                                                <ul class="todo_list">
                                                    <li><label>1</label><div class="task_ans">I completed 100 body weight squats today<div class="yes_no_box"><span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span><span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></div></li>
                                                    <li><label>2</label><div class="task_ans">I did 100 pushups<div class="yes_no_box"><span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span><span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></div></li>
                                                </ul>
                                            </div>
                                            <a class="redirect_btn" href="https://reachvine.com/ef08d0" target="_blank">Ready to perform<i class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="card_wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#999999" d="M394.633,220.663,269.475,25.174a16,16,0,0,0-26.95,0L117.364,220.665A170.531,170.531,0,0,0,84.1,322.3c0,94.785,77.113,171.9,171.9,171.9s171.9-77.113,171.9-171.9A170.519,170.519,0,0,0,394.633,220.663ZM256,462.2c-77.14,0-139.9-62.758-139.9-139.9a138.758,138.758,0,0,1,27.321-83.058q.319-.432.608-.884L256,63.475,367.967,238.359q.288.453.608.884A138.754,138.754,0,0,1,395.9,322.3C395.9,399.441,333.14,462.2,256,462.2Z"/></svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">6 Week Habits<span class="tag">Ongoing</span><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_title">Hydration</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label><div class="task_ans">I drank my snake juice<div class="yes_no_box"><span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span><span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></div></li>
                                                    <li><label>2</label><div class="task_ans">I drank one gallon of water today<div class="yes_no_box"><span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span><span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></div></li>
                                                </ol>
                                                <div class="block_title">Strength</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label><div class="task_ans">I completed 100 body weight squats today<div class="yes_no_box"><span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span><span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></div></li>
                                                    <li><label>2</label><div class="task_ans">I did 100 pushups<div class="yes_no_box"><span class="yes_box"><i class="fas fa-thumbs-up"></i>Yes</span><span class="no_box"><i class="fas fa-thumbs-down"></i>No</span></div></div></li>
                                                </ol>
                                            </div>
                                            <a class="redirect_btn" href="https://reachvine.com/ef08d0" target="_blank">Ready to perform<i class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <h3 class="sub_heading2 py-1 m-0 rotate" @click="toggleList($event)">Progress Check-ins<i class="fas fa-caret-down"></i></h3>
                        <ul class="workout_card show">
                            <li>
                                <div class="card_wrapper">
                                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 22H14C19 22 21 20 21 15V9C21 4 19 2 14 2H10C5 2 3 4 3 9V15C3 20 5 22 10 22Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M17.25 8.29004C14.26 5.63004 9.74 5.63004 6.75 8.29004L8.93 11.79C10.68 10.23 13.32 10.23 15.07 11.79L17.25 8.29004Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">21 Day Makeover <span class="tag">Until June 30, 2023</span><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_title">Progress Questions</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label>What is your weight today?</li>
                                                    <li><label>2</label>What are your circumference measurements today?</li>
                                                </ol>
                                                <div class="block_title">Hydration</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label>How many pushups did you complete in 1 minute?</li>
                                                </ol>
                                            </div>
                                            <a class="redirect_btn" href="https://reachvine.com/d550a9" target="_blank">Ready to perform<i class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="card_wrapper">
                                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 22H14C19 22 21 20 21 15V9C21 4 19 2 14 2H10C5 2 3 4 3 9V15C3 20 5 22 10 22Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M17.25 8.29004C14.26 5.63004 9.74 5.63004 6.75 8.29004L8.93 11.79C10.68 10.23 13.32 10.23 15.07 11.79L17.25 8.29004Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">28 Day Makeover <span class="tag">Until June 30, 2023</span><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_title">Progress Questions</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label>What is your weight today?</li>
                                                    <li><label>2</label>What is your body fat percentage today?</li>
                                                    <li><label>3</label>Are you submitting progress pics with this report?</li>
                                                </ol>
                                                <div class="block_title">Strength</div>
                                                <ol class="todo_list">
                                                    <li><label>1</label>How many squats would you like to be able to do in a day?</li>
                                                    <li><label>2</label>How many pushups would you like to be able to do in one minute?</li>
                                                </ol>
                                            </div>
                                            <a class="redirect_btn" href="https://reachvine.com/bf6645" target="_blank">Ready to perform<i class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    <!-- <div class="setting_wpr py-2" v-if="ev_type == 'event'"> -->
                        <h3 class="sub_heading2 py-1 m-0 rotate" @click="toggleList($event)">Events <i class="fas fa-caret-down"></i></h3>
                        <ul class="workout_card show">
                            <li>
                                <div class="card_wrapper">
                                    <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.5" y="8.53442" width="37.3448" height="31.9655" rx="2.5" stroke="#999999" stroke-width="3"/>
                                        <rect x="7.10693" y="1.5" width="5.15172" height="11.069" rx="2.57586" fill="#999999" stroke="white" stroke-width="3"/>
                                        <rect x="28.0862" y="1.5" width="5.15172" height="11.069" rx="2.57586" fill="#999999" stroke="white" stroke-width="3"/>
                                        <circle cx="11.8" cy="21.8" r="2.80002" fill="#999999"/>
                                        <circle cx="11.8" cy="30.1999" r="2.80002" fill="#999999"/>
                                        <circle cx="20.4155" cy="21.8" r="2.80002" fill="#999999"/>
                                        <circle cx="20.4155" cy="30.1999" r="2.80002" fill="#999999"/>
                                        <circle cx="29.2463" cy="21.8" r="2.80002" fill="#999999"/>
                                        <circle cx="29.2463" cy="30.1999" r="2.80002" fill="#999999"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">Discover the Possibilities <i class="fas fa-chevron-down"></i></h4>
                                        <div class="details booking_details">
                                            <!-- <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Event type</h5>
                                                    <h6>Discover the Possibilities!</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Date</h5>
                                                    <h6>25th June, 2023</h6>
                                                </div>
                                            </div> -->
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Time</h5>
                                                    <h6>09:30am - 10:00am</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Duration</h5>
                                                    <h6>30 min.</h6>
                                                </div>
                                            </div>
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Timezone</h5>
                                                    <h6>Atlantic New York</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Coach Name</h5>
                                                    <h6>Joe Trainer</h6>
                                                </div>
                                            </div>
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Name</h5>
                                                    <h6>Robert Bacins</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Email</h5>
                                                    <h6>robert@gmail.com</h6>
                                                </div>
                                            </div>
                                            <div class="additional_info">
                                                <h5>Asked question</h5>
                                                <p>Etiam egestas fringilla dolor vel aliquam. Morbi vel est fermentum quam laoreet facilisis.</p>
                                            </div>
                                            <div class="additional_info">
                                                <h5>Event location</h5>
                                                <h6>Google Meet :<a class="link">&#123;&#123; google meet link &#125;&#125;</a></h6>
                                            </div>
                                            <div class="action_btns">
                                                <a><i class="fas fa-sync"></i>Reschedule</a>
                                                <a class="delete_btn">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="card_wrapper">
                                    <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.5" y="8.53442" width="37.3448" height="31.9655" rx="2.5" stroke="#999999" stroke-width="3"/>
                                        <rect x="7.10693" y="1.5" width="5.15172" height="11.069" rx="2.57586" fill="#999999" stroke="white" stroke-width="3"/>
                                        <rect x="28.0862" y="1.5" width="5.15172" height="11.069" rx="2.57586" fill="#999999" stroke="white" stroke-width="3"/>
                                        <circle cx="11.8" cy="21.8" r="2.80002" fill="#999999"/>
                                        <circle cx="11.8" cy="30.1999" r="2.80002" fill="#999999"/>
                                        <circle cx="20.4155" cy="21.8" r="2.80002" fill="#999999"/>
                                        <circle cx="20.4155" cy="30.1999" r="2.80002" fill="#999999"/>
                                        <circle cx="29.2463" cy="21.8" r="2.80002" fill="#999999"/>
                                        <circle cx="29.2463" cy="30.1999" r="2.80002" fill="#999999"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">Summer Shred Challenge!<i class="fas fa-chevron-down"></i></h4>
                                        <div class="details booking_details">
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Time</h5>
                                                    <h6>11:00am - 11:30am</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Duration</h5>
                                                    <h6>30 min.</h6>
                                                </div>
                                            </div>
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Timezone</h5>
                                                    <h6>Atlantic New York</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Coach Name</h5>
                                                    <h6>Joe Trainer</h6>
                                                </div>
                                            </div>
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Name</h5>
                                                    <h6>Robert Bacins</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Email</h5>
                                                    <h6>robert@gmail.com</h6>
                                                </div>
                                            </div>
                                            <div class="additional_info">
                                                <h5>Asked question</h5>
                                                <p>Etiam egestas fringilla dolor vel aliquam. Morbi vel est fermentum quam laoreet facilisis.</p>
                                            </div>
                                            <div class="additional_info">
                                                <h5>Event location</h5>
                                                <h6>Phone call :</h6>
                                                <div class="location_details">
                                                    <div class="location_fld">
                                                        <input type="text" value="(814) 555-3298">
                                                        <a class="suffix" href="tel:(814) 555-3298">
                                                            <img src="@/assets/images/phone-2.svg" alt="">
                                                        </a>
                                                    </div>
                                                    <label for="has-record" class="checkbox_opt">
                                                        <input type="checkbox" id="has-record" name="has-record" hidden="">
                                                        <div class="check_box"><i class="fas fa-check"></i></div>
                                                        <h5>Record</h5>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="action_btns">
                                                <a><i class="fas fa-sync"></i>Reschedule</a>
                                                <a class="delete_btn">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="card_wrapper">
                                    <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.5" y="8.53442" width="37.3448" height="31.9655" rx="2.5" stroke="#999999" stroke-width="3"/>
                                        <rect x="7.10693" y="1.5" width="5.15172" height="11.069" rx="2.57586" fill="#999999" stroke="white" stroke-width="3"/>
                                        <rect x="28.0862" y="1.5" width="5.15172" height="11.069" rx="2.57586" fill="#999999" stroke="white" stroke-width="3"/>
                                        <circle cx="11.8" cy="21.8" r="2.80002" fill="#999999"/>
                                        <circle cx="11.8" cy="30.1999" r="2.80002" fill="#999999"/>
                                        <circle cx="20.4155" cy="21.8" r="2.80002" fill="#999999"/>
                                        <circle cx="20.4155" cy="30.1999" r="2.80002" fill="#999999"/>
                                        <circle cx="29.2463" cy="21.8" r="2.80002" fill="#999999"/>
                                        <circle cx="29.2463" cy="30.1999" r="2.80002" fill="#999999"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">The Ultimate Fitness Challenge!<i class="fas fa-chevron-down"></i></h4>
                                        <div class="details booking_details">
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Time</h5>
                                                    <h6>04:30pm - 05:00pm</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Duration</h5>
                                                    <h6>30 min.</h6>
                                                </div>
                                            </div>
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Timezone</h5>
                                                    <h6>Atlantic New York</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Coach Name</h5>
                                                    <h6>Joe Trainer</h6>
                                                </div>
                                            </div>
                                            <div class="group_details">
                                                <div class="item_details">
                                                    <h5>Name</h5>
                                                    <h6>Robert Bacins</h6>
                                                </div>
                                                <div class="item_details">
                                                    <h5>Email</h5>
                                                    <h6>robert@gmail.com</h6>
                                                </div>
                                            </div>
                                            <div class="additional_info">
                                                <h5>Asked question</h5>
                                                <p>Etiam egestas fringilla dolor vel aliquam. Morbi vel est fermentum quam laoreet facilisis.</p>
                                            </div>
                                            <div class="additional_info">
                                                <h5>Event location</h5>
                                                <h6>In-person :</h6>
                                                <div class="location_details">
                                                    <div class="location_fld">
                                                        <input type="text" value="123 Super Street Supertown, CO 12345">
                                                        <a class="suffix" @click="show_map = true;"><img src="@/assets/images/icon2.svg" alt=""></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="action_btns">
                                                <a><i class="fas fa-sync"></i>Reschedule</a>
                                                <a class="delete_btn">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <h3 class="sub_heading2 py-1 m-0 rotate">Content Unlocked!<i class="fas fa-caret-down"></i></h3>
                    </div>
                </div>
                <div class="modal_footer">
                    <button type="button" class="btn cancel_btn" @click="workout_details = false">Cancel</button>
                    <button type="button" class="btn save_btn">Save</button>
                </div>
            </div>
        </div>
        <div class="modal secondary video_player" v-if="show_map">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="show_map = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2879.0810626602415!2d-91.23143862329779!3d43.812677942100905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87f9558be56b1ad7%3A0x77cee9d58f36261f!2sSuper%20Street%20Tacos!5e0!3m2!1sen!2sin!4v1686902816627!5m2!1sen!2sin" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const SettingComponent = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/Setting'))
    const CoverSetting = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/CoverSetting'))
    const HeaderSetting = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/HeaderSetting'))
    const PlaybookComponent = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/Playbook'))
    const PageComponent = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/Page'))
    const FormComponent = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/Form'))
    const ProgressComponent = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/Progress'))
    const WorkoutComponent = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/Workout'))
    const LoginPreview = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/LoginPreview'))
    const PortalPreview = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/PortalPreview'))
    const FooterSetting = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/FooterSetting'))
    const Chat = defineAsyncComponent(() => import('@/components/Chat'))
    const PlanView = defineAsyncComponent(() => import('@/pages/client-portal-ui/components/PlanView'))

    import moment from 'moment'
    import FullCalendar from '@fullcalendar/vue3'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import interactionPlugin from '@fullcalendar/interaction'
    import timeGridPlugin from '@fullcalendar/timegrid'

    export default {
        name: 'ClientPortal',

        data () {
            return {
                tutorial: false,
                previewPortal: false,
                previewLogin: false,
                portalSetting: false,
                coverSetting: false,
                footerSetting: false,
                headerSetting: false,
                navigationTab: 'dashboard',
                classroomTab: 'playbooks',
                showWelcomeVideo: false,
                loadedContent: false,
                chat: false,
                coverImage: '',
                form: {
                    display_cover: 1,
                    cover_type: 2,
                    cover: '',//require('@/assets/images/man-deadlift.jpg'),
                    headline: 'WELCOME TO',
                    description: 'YOUR CLIENT PORTAL',
                    overlay_color: '',
                    opac_overlay_color: '5',
                    pinned_widget: 1,
                    welcome_widget: 1,
                    has_welcome_video: 1,
                    has_announcement: 1,
                    activity_widget: 1,
                    announcement_widget: 1,
                    cover_background_color: '#2F7FED',
                    headline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgb(0, 0, 0, 0.25)',
                    },
                    subheadline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgb(0, 0, 0, 0.68)',
                    },
                    habit_score_color: '#2F7FED',
                    progress_score_color: '#F2A31D',
                    login_score_color: '#95D7EF',
                    referral_score_color: '#999999',
                },
                workout_details: false,
                completed_view: false,
                ev_type: '',
                selected_date: '',
                show_calendar: true,
                see_plan: false,
                filterList: false,
                searchField: false,
                params: {
                    per_page: 8,
                    page: 1,
                    category_id: null,
                    search: '',
                },
                isTyping: false,
                is_complete_btn : 0,
                calendarOptions: {
                    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                    timeZone: 'UTC',
                    initialView: 'dayGridMonth',
                    headerToolbar: {
                        left: 'prev,next',
                        center: 'title',
                        right: 'dayGridMonth dayGridWeek dayGridDay complete incomplete'
                        // right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    },
                    customButtons: {
                        complete: {
                            text: 'Complete',
                            click: this.customBtnClick
                        },
                        incomplete: {
                            text: 'Incomplete',
                            click: this.customBtnClick
                        }
                    },
                    eventClick: this.handleEventClick,
                    editable: true,
                    dayMaxEvents: 2,
                    events: [
                        {
                            title: '1 Event',
                            start: "2023-07-17",
                            completed: 1,
                            events:[
                                {
                                    type: 'workout',
                                    desc: 'Week 1 Foundation - Pull',
                                    period: 'am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Discover the Possibilities',
                                    time: '09:00am - 10:00am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Summer Shred Challenge!',
                                    time: '11:00am - 11:30am',
                                    completed: 1,
                                },
                            ],
                        },
                        {
                            title: '1 Event',
                            start: "2023-07-19",
                            completed: 0,
                            events:[
                                {
                                    type: 'workout',
                                    desc: 'Week 1 Foundation - Leg & Core',
                                    period: 'am',
                                    completed: 1,
                                },
                                {
                                    type: 'workout',
                                    desc: 'Week 1 Foundation - Pull',
                                    period: 'am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Discover the Possibilities',
                                    time: '09:00am - 10:00am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Summer Shred Challenge!',
                                    time: '11:00am - 11:30am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'The Ultimate Fitness Challenge!',
                                    time: '04:30am - 05:00am',
                                    completed: 1,
                                },
                            ],
                        },
                        {
                            title: '1 Event',
                            start: "2023-07-21",
                            completed: 0,
                            events:[
                                {
                                    type: 'workout',
                                    desc: 'Week 1 Foundation - Leg & Core',
                                    period: 'am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Discover the Possibilities',
                                    time: '09:00am - 10:00am',
                                    completed: 1,
                                },
                            ],
                        },
                        {
                            title: '1 Event',
                            start: "2023-07-24",
                            completed: 0,
                            events:[
                                {
                                    type: 'event',
                                    desc: 'Summer Shred Challenge!',
                                    time: '11:00am - 11:30am',
                                    completed: 1,
                                },
                            ],
                        },
                        {
                            title: '1 Event',
                            start: "2023-07-26",
                            completed: 0,
                            events:[
                                {
                                    type: 'workout',
                                    desc: 'Week 1 Foundation - Leg & Core',
                                    period: 'am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Discover the Possibilities',
                                    time: '09:00am - 10:00am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Summer Shred Challenge!',
                                    time: '11:00am - 11:30am',
                                    completed: 1,
                                },
                            ],
                        },
                        {
                            title: '1 Event',
                            start: "2023-07-28",
                            completed: 0,
                            events:[
                                {
                                    type: 'workout',
                                    desc: 'Week 1 Foundation - Pull',
                                    period: 'am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Discover the Possibilities',
                                    time: '09:00am - 10:00am',
                                    completed: 1,
                                },
                            ],
                        },
                        {
                            title: '1 Event',
                            start: "2023-07-31",
                            completed: 0,
                            events:[
                                {
                                    type: 'workout',
                                    desc: 'Week 1 Foundation - Leg & Core',
                                    period: 'am',
                                    completed: 1,
                                },
                                {
                                    type: 'workout',
                                    desc: 'Week 1 Foundation - Pull',
                                    period: 'am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Discover the Possibilities',
                                    time: '09:00am - 10:00am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Summer Shred Challenge!',
                                    time: '11:00am - 11:30am',
                                    completed: 1,
                                },
                            ],
                        },
                        {
                            title: '1 Event',
                            start: "2023-08-02",
                            completed: 0,
                            events:[
                                {
                                    type: 'workout',
                                    desc: 'Week 1 Foundation - Leg & Core',
                                    period: 'am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Discover the Possibilities',
                                    time: '09:00am - 10:00am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Summer Shred Challenge!',
                                    time: '11:00am - 11:30am',
                                    completed: 1,
                                },
                            ],
                        },
                        {
                            title: '1 Event',
                            start: "2023-08-04",
                            completed: 0,
                            events:[
                                {
                                    type: 'workout',
                                    desc: 'Week 1 Foundation - Leg & Core',
                                    period: 'am',
                                    completed: 1,
                                },
                                {
                                    type: 'event',
                                    desc: 'Discover the Possibilities',
                                    time: '09:00am - 10:00am',
                                    completed: 1,
                                },
                            ],
                        },
                    ],
                    height: "auto",
                    contentHeight: "auto",
                    views: {
                        dayGridMonth: {
                            eventContent: function(arg) {
                                let el = document.createElement('label');
                                let el2 = document.createElement('div');
                                el2.classList.add('quick_info');

                                if(arg.event.extendedProps.events){
                                    el.innerHTML = `${arg.event.extendedProps.events.length} Event${arg.event.extendedProps.events.length > 1 ? 's' : ''}`;
                                    JSON.stringify(arg.event.extendedProps.events.forEach((item, i) => el2.innerHTML += `<div class="event_info"><span>${i + 1}</span><h5>${item.desc}</h5></div>`));
                                }

                                let arrayOfDomNodes = [ el, el2 ];
                                return { domNodes: arrayOfDomNodes};
                            }
                        },
                        dayGridWeek: {
                            eventContent: function(arg) {
                                let el = document.createElement('div');
                                el.classList.add('activity_list');

                                if(arg.event.extendedProps.events){
                                    JSON.stringify(arg.event.extendedProps.events.forEach((item, i) => {
                                        if(item.type === 'workout'){
                                            el.innerHTML += `<div class="list_item">
                                                <svg width="80" height="93" viewBox="0 0 80 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" stroke="#999999" stroke-width="5"/>
                                                <path d="M17.8241 12.762C12.3284 15.751 11.2758 21.319 11.4365 23.7295C11.7981 27.9477 15.5342 31.0813 16.9805 32.166C18.1375 33.0337 19.7123 32.5275 20.3551 32.166C26.8632 28.6708 31.5636 27.4656 41.0848 27.3451C50.6059 27.2246 57.9578 31.6839 59.5245 32.407C60.778 32.9855 61.7341 32.648 62.0555 32.407C66.4907 29.4181 67.8405 25.3766 67.961 23.7295C68.5636 15.6545 60.7297 12.4005 60.0066 12.1594C59.4281 11.9666 57.8372 11.838 57.1141 11.7979C47.7134 11.1952 22.886 11.7979 21.0782 11.7979C19.632 11.7979 18.3062 12.4406 17.8241 12.762Z" stroke="#999999" stroke-width="5"/>
                                            </svg>${item.desc}</div>`;
                                        }else if(item.type === 'event'){
                                            el.innerHTML += `<div class="list_item">
                                                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="1" y="7.03442" width="38.3448" height="32.9655" rx="3" stroke="#999999" stroke-width="2"/>
                                                <rect x="7.60693" y="1" width="4.15172" height="10.069" rx="2.07586" fill="#999999" stroke="white" stroke-width="2"/>
                                                <rect x="28.5862" y="1" width="4.15172" height="10.069" rx="2.07586" fill="#999999" stroke="white" stroke-width="2"/>
                                                <circle cx="11.8" cy="20.8" r="2.80002" fill="#B8B8B8"/>
                                                <circle cx="11.8" cy="29.1999" r="2.80002" fill="#B8B8B8"/>
                                                <circle cx="20.4155" cy="20.8" r="2.80002" fill="#B8B8B8"/>
                                                <circle cx="20.4155" cy="29.1999" r="2.80002" fill="#B8B8B8"/>
                                                <circle cx="29.2463" cy="20.8" r="2.80002" fill="#B8B8B8"/>
                                                <circle cx="29.2463" cy="29.1999" r="2.80002" fill="#B8B8B8"/>
                                            </svg>${item.desc}</div>`;
                                        }else if(item.type === 'progress'){
                                            el.innerHTML += `<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 22H14C19 22 21 20 21 15V9C21 4 19 2 14 2H10C5 2 3 4 3 9V15C3 20 5 22 10 22Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.25 8.29004C14.26 5.63004 9.74 5.63004 6.75 8.29004L8.93 11.79C10.68 10.23 13.32 10.23 15.07 11.79L17.25 8.29004Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>${item.desc}</div>`;
                                        }else if(item.type === 'habit'){
                                            el.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#999999" d="M394.633,220.663,269.475,25.174a16,16,0,0,0-26.95,0L117.364,220.665A170.531,170.531,0,0,0,84.1,322.3c0,94.785,77.113,171.9,171.9,171.9s171.9-77.113,171.9-171.9A170.519,170.519,0,0,0,394.633,220.663ZM256,462.2c-77.14,0-139.9-62.758-139.9-139.9a138.758,138.758,0,0,1,27.321-83.058q.319-.432.608-.884L256,63.475,367.967,238.359q.288.453.608.884A138.754,138.754,0,0,1,395.9,322.3C395.9,399.441,333.14,462.2,256,462.2Z"/>
                                            </svg>${item.desc}</div>`;
                                        }
                                    }));
                                }

                                let arrayOfDomNodes = [ el ];
                                return { domNodes: arrayOfDomNodes};
                            }
                        },
                        dayGridDay: {
                            eventContent: function(arg) {
                                let el = document.createElement('div');
                                el.classList.add('activity_list');

                                if(arg.event.extendedProps.events){
                                    JSON.stringify(arg.event.extendedProps.events.forEach((item, i) => {
                                        if(item.type === 'workout'){
                                            el.innerHTML += `<div class="list_item">
                                                <svg width="80" height="93" viewBox="0 0 80 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" stroke="#999999" stroke-width="5"/>
                                                <path d="M17.8241 12.762C12.3284 15.751 11.2758 21.319 11.4365 23.7295C11.7981 27.9477 15.5342 31.0813 16.9805 32.166C18.1375 33.0337 19.7123 32.5275 20.3551 32.166C26.8632 28.6708 31.5636 27.4656 41.0848 27.3451C50.6059 27.2246 57.9578 31.6839 59.5245 32.407C60.778 32.9855 61.7341 32.648 62.0555 32.407C66.4907 29.4181 67.8405 25.3766 67.961 23.7295C68.5636 15.6545 60.7297 12.4005 60.0066 12.1594C59.4281 11.9666 57.8372 11.838 57.1141 11.7979C47.7134 11.1952 22.886 11.7979 21.0782 11.7979C19.632 11.7979 18.3062 12.4406 17.8241 12.762Z" stroke="#999999" stroke-width="5"/>
                                            </svg>${item.desc}</div>`;
                                        }else if(item.type === 'event'){
                                            el.innerHTML += `<div class="list_item">
                                                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="1" y="7.03442" width="38.3448" height="32.9655" rx="3" stroke="#999999" stroke-width="2"/>
                                                <rect x="7.60693" y="1" width="4.15172" height="10.069" rx="2.07586" fill="#999999" stroke="white" stroke-width="2"/>
                                                <rect x="28.5862" y="1" width="4.15172" height="10.069" rx="2.07586" fill="#999999" stroke="white" stroke-width="2"/>
                                                <circle cx="11.8" cy="20.8" r="2.80002" fill="#B8B8B8"/>
                                                <circle cx="11.8" cy="29.1999" r="2.80002" fill="#B8B8B8"/>
                                                <circle cx="20.4155" cy="20.8" r="2.80002" fill="#B8B8B8"/>
                                                <circle cx="20.4155" cy="29.1999" r="2.80002" fill="#B8B8B8"/>
                                                <circle cx="29.2463" cy="20.8" r="2.80002" fill="#B8B8B8"/>
                                                <circle cx="29.2463" cy="29.1999" r="2.80002" fill="#B8B8B8"/>
                                            </svg>${item.desc}</div>`;
                                        }else if(item.type === 'progress'){
                                            el.innerHTML += `<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 22H14C19 22 21 20 21 15V9C21 4 19 2 14 2H10C5 2 3 4 3 9V15C3 20 5 22 10 22Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.25 8.29004C14.26 5.63004 9.74 5.63004 6.75 8.29004L8.93 11.79C10.68 10.23 13.32 10.23 15.07 11.79L17.25 8.29004Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>${item.desc}</div>`;
                                        }else if(item.type === 'habit'){
                                            el.innerHTML += `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#999999" d="M394.633,220.663,269.475,25.174a16,16,0,0,0-26.95,0L117.364,220.665A170.531,170.531,0,0,0,84.1,322.3c0,94.785,77.113,171.9,171.9,171.9s171.9-77.113,171.9-171.9A170.519,170.519,0,0,0,394.633,220.663ZM256,462.2c-77.14,0-139.9-62.758-139.9-139.9a138.758,138.758,0,0,1,27.321-83.058q.319-.432.608-.884L256,63.475,367.967,238.359q.288.453.608.884A138.754,138.754,0,0,1,395.9,322.3C395.9,399.441,333.14,462.2,256,462.2Z"/>
                                            </svg>${item.desc}</div>`;
                                        }
                                    }));
                                }

                                let arrayOfDomNodes = [ el ];
                                return { domNodes: arrayOfDomNodes};
                            }
                        }
                    },
                },
                show_map: false,
            }
        },

        components: {
            SettingComponent,
            CoverSetting,
            HeaderSetting,
            PlaybookComponent,
            PageComponent,
            FormComponent,
            ProgressComponent,
            WorkoutComponent,
            LoginPreview,
            PortalPreview,
            Chat,
            FooterSetting,
            FullCalendar,
            PlanView
        },

        watch: {
            clientPortal () {
                const vm = this;

                vm.resetForm();
            },

            coverImage (cover) {
                const vm = this;

                vm.updatePortalSetting({ cover });
            },

            previewPortal (val) {
                const vm = this;

                if (val == true) {
                    document.body.classList.add('modal-open');
                } else if (val == false && vm.portalSetting) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                vm.toggleGistChatBox();
            },

            previewLogin (val) {
                const vm = this;

                if (val == true) {
                    document.body.classList.add('modal-open');
                } else if (val == false && vm.portalSetting) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                vm.toggleGistChatBox();
            },

            portalSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            coverSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            footerSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            headerSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            workout_details(val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            clientPortal: state => state.clientPortalModule.clientPortal,
            clientPortalLoader: state => state.clientPortalModule.clientPortalLoader,
            pinnedPlaybooks: state => state.clientPortalModule.pinnedPlaybooks,
            assetsLoader: state => state.clientPortalModule.assetsLoader,
            updateLoader: state => state.clientPortalModule.updateLoader,
            lastRefreshedAt: state => state.clientPortalModule.lastRefreshedAt,

            playbookCategories: state => state.clientPortalModule.playbookCategories,
        }),

        mounted () {
            const vm = this;

            if (!vm.clientPortal.id || vm.lastRefreshedAt && moment().diff(vm.lastRefreshedAt, 'hours') > 1) {
                vm.getClientPortal();
            } else {
                vm.resetForm();
            }

            vm.getPlaybooks(vm.params);
            vm.getPinnedPlaybooks();
        },

        methods: {
            ...mapActions({
                getClientPortal: 'clientPortalModule/getClientPortal',
                getPlaybooks: 'clientPortalModule/getPlaybooks',
                getPinnedPlaybooks: 'clientPortalModule/getPinnedPlaybooks',
                updatePortalSetting: 'clientPortalModule/updatePortalSetting',
            }),

            resetForm () {
                const vm = this;
                let headline_setting = vm.clientPortal.headline_setting ? JSON.parse(vm.clientPortal.headline_setting) : {};
                let subheadline_setting = vm.clientPortal.subheadline_setting ? JSON.parse(vm.clientPortal.subheadline_setting) : {};

                vm.loadedContent = false;

                if (!headline_setting.font_size) {
                    headline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    };
                }

                if (!subheadline_setting.font_size) {
                    subheadline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.68)',
                    };
                }

                vm.form = {
                    headline_setting,
                    subheadline_setting,
                    display_cover: vm.clientPortal.display_cover ? vm.clientPortal.display_cover : 0,
                    cover: vm.clientPortal.cover ? vm.clientPortal.cover : '',
                    small_cover: vm.clientPortal.small_cover ? vm.clientPortal.small_cover : '',
                    cover_type: vm.clientPortal.cover_type ? vm.clientPortal.cover_type : 2,
                    headline: vm.clientPortal.headline ? vm.clientPortal.headline : 'Welcome To Your Portal!',
                    description: vm.clientPortal.description ? vm.clientPortal.description : '',
                    overlay_color: vm.clientPortal.overlay_color ? vm.clientPortal.overlay_color : '',
                    opac_overlay_color: vm.clientPortal.opac_overlay_color ? vm.clientPortal.opac_overlay_color : 'rgb(0, 0, 0, 0.05)',
                    has_cover_overlay: vm.clientPortal.has_cover_overlay ? vm.clientPortal.has_cover_overlay : 0,
                    overlay_opacity: vm.clientPortal.overlay_opacity ? vm.clientPortal.overlay_opacity : '5',
                    display_footer: vm.clientPortal.display_footer ? vm.clientPortal.display_footer : 0,
                    footer_company: vm.clientPortal.footer_company ? vm.clientPortal.footer_company : `${new Date().getFullYear()} - Thrive Coach`,
                    white_label: vm.clientPortal.white_label ? vm.clientPortal.white_label : 'Powered By Thrive Coach',
                    pinned_widget: vm.clientPortal.pinned_widget ? vm.clientPortal.pinned_widget : 0,
                    welcome_widget: vm.clientPortal.welcome_widget ? vm.clientPortal.welcome_widget : 0,
                    activity_widget: vm.clientPortal.activity_widget ? vm.clientPortal.activity_widget : 0,
                    announcement_widget: vm.clientPortal.announcement_widget ? vm.clientPortal.announcement_widget : 0,
                    has_welcome_video: vm.clientPortal.has_welcome_video ? vm.clientPortal.has_welcome_video : 0,
                    has_announcement: vm.clientPortal.has_announcement ? vm.clientPortal.has_announcement : 0,
                    cover_background_color: vm.clientPortal.cover_background_color ? vm.clientPortal.cover_background_color : '#2F7FED',
                    habit_score_color: vm.clientPortal.habit_score_color ? vm.clientPortal.habit_score_color : '#2F7FED',
                    progress_score_color: vm.clientPortal.progress_score_color ? vm.clientPortal.progress_score_color : '#F2A31D',
                    login_score_color: vm.clientPortal.login_score_color ? vm.clientPortal.login_score_color : '#95D7EF',
                    referral_score_color: vm.clientPortal.referral_score_color ? vm.clientPortal.referral_score_color : '#999999',
                };

                setTimeout(function () {
                    vm.loadedContent = true;
                }, 1000);
            },

            updatePortal (params) {
                const vm = this;

                if (vm.loadedContent) {
                    vm.updatePortalSetting(params);
                }
            },
            handleEventClick(clickInfo) {
                this.workout_details = true;
                if(clickInfo.event.extendedProps.completed){
                    this.completed_view = true;
                }else{
                    this.completed_view = false;
                }
                this.ev_type = clickInfo.event.extendedProps.type;
                this.selected_date = clickInfo.event.start;
            },
            customBtnClick(el){
                let x = el.currentTarget;
                let y = x.parentNode;

                if(x.classList.contains('fc-complete-button')){
                    this.is_complete_btn = 1;
                    y.querySelector('.fc-complete-button').classList.add('fc-button-active');
                    y.querySelector('.fc-incomplete-button').classList.remove('fc-button-active');
                }else{
                    this.is_complete_btn = 0;
                    y.querySelector('.fc-incomplete-button').classList.add('fc-button-active');
                    y.querySelector('.fc-complete-button').classList.remove('fc-button-active');
                }
            },
            closeFilters () {
                const vm = this;

                vm.filterList = false;
            },
            toggleList(ev){
                const el = ev.currentTarget;
                if(el.nextSibling.classList.contains('show')){
                    el.nextSibling.classList.remove('show');
                    el.classList.remove('rotate');
                }else{
                    el.nextSibling.classList.add('show');
                    el.classList.add('rotate');
                }
            },
            showDetails(ev){
                const el = ev.currentTarget.closest('li');
                if(el.classList.contains('show')){
                    el.classList.remove('show');
                }else{
                    el.classList.add('show');
                }
            }
        },
    }
</script>

<style scoped>
    .section_header {
        justify-content: flex-start;
    }
    .profile_spinner_wpr {
        z-index: 8;
    }
    .dashboard_content{
        margin-bottom: 50px;
    }
    .dashboard_content h3{
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 15px;
    }
    .dashboard_content .client_productList{
        margin: 0 -15px;
    }
    .dashboard_content .client_productList li{
        padding: 15px;
    }
    .dashboard_content .right_side .extra_info{
        border: 0;
    }
    .dashboard_content .right_side .extra_info .action_wpr{
        position: relative;
        margin-bottom: -10px;
    }
    :deep(.video_wpr) {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        /* padding-top: 25px; */
        /* margin-bottom: 15px; */
    }

    :deep(.video_wpr iframe) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .video-overlay {
        position: absolute;
        z-index: +9;
        height: 100%;
        width: 100%;
        top: 0;
        pointer-events: none;
    }

    .playbook-desc h4 {
        height: 40px;
    }

    .playbook-desc p {
        height: 50px;
    }

    :deep(.button-uploader) {
        position: absolute;
        left: -41px;
        top: 25px;
        background: #fff;
        z-index: 3;
        height: 41px;
        width: 41px;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
    }
    :deep(.card_body:hover .button-uploader){
        left: 25px;
    }

    :deep(.button-uploader .image_setting) {
        height: 41px;
        width: 41px;
        position: relative;
    }

    :deep(.button-uploader .upload_btn) {
        left: 0;
        top: 0;
    }

    :deep(.upload_btn .select_img) {
        height: 21px !important;
    }

    :deep(.tab_btn li.active) {
        background-color: var(--var-portal-tab-background-color);
        border-color: var(--var-portal-tab-background-color);
        color: var(--var-portal-tab-text-color) !important;
    }

    :deep(.Pagination li button.Page-active) {
        background-color: var(--var-portal-tab-background-color) !important;
        border-color: var(--var-portal-tab-background-color) !important;
        color: var(--var-portal-tab-text-color) !important;
    }

    :deep(.pin.custom-pin i){
        color: var(--var-portal-tab-background-color) !important;
    }
    :deep(.custom_element) {
        background-color: var(--var-portal-tab-background-color) !important;
        border-color: var(--var-portal-tab-background-color) !important;
        color: var(--var-portal-tab-text-color) !important;
    }
    .chatbox_wpr {
        padding-top: 15px;
    }

    :deep(.chatbox_wpr .chat_box .input_area) {
        background: #FFF;
    }

    /* :deep(.chatbox_wpr .chat_box button), */
    :deep(.chatbox_wpr .chat_box .chat-emoji-picker),
    :deep(.chatbox_wpr .chat_box .dropdown) {
        background: #FFF;
    }

    :deep(.chat_btn) {
        box-shadow: none;
    }

    :deep(.activity_score h5) {
        color: #656565;
    }

    :deep(.banner_area2 .banner_wpr h1.shadow-none),
    :deep(.banner_area2 .banner_wpr h4.shadow-none),
    :deep(.banner_area3 .banner_wpr h1.shadow-none),
    :deep(.banner_area3 .banner_wpr h4.shadow-none),
    :deep(.banner_area .banner_wpr h1.shadow-none),
    :deep(.banner_area .banner_wpr h4.shadow-none),
    :deep(.banner_wpr .bnrCont_wpr h1.shadow-none),
    :deep(.banner_wpr .bnrCont_wpr h4.shadow-none),
    :deep(.client_card .card_body h1.shadow-none),
    :deep(.client_card .card_body h4.shadow-none) {
        text-shadow: none;
    }
    .draft *{
        opacity: 0.5;
    }

    button.header_setting {
        position: relative;
        margin-right: -78px;
        margin-left: 32px;
        padding: 6px 15px;
        border-radius: 16px 0 0 16px;
        background: #fff;
        color: #9f9e9e;
        font-size: 14px;
        cursor: pointer;
        border: 1px solid #d9d9d9;
        border-right: 0;
        transition: all 0.3s ease-in-out;
    }

    .client_card .card_header:hover button.header_setting{
        margin-right: -30px;
    }
    .client_card .card_header .user_box{
        margin-left: auto;
    }

    .client_card .card_header .dashboard-menu-text h4 {
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        border: 1px solid #d9d9d9;
        padding: 5px 15px;
        border-radius: 20px;
    }

    .card_header nav {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid #e9e9e9;
    }

    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 35px;
    }

    /* .cell .logo_img {
        max-height: 25px;
        width: auto;
    } */
    .modal.workout_modal .modal_body{
        padding-top: 0;
        background: #fff;
    }
    /* .workout_list {
        background: #f5f5f5;
        border-bottom: 1px solid #eaeaea;
        margin: 0 -45px;
        padding: 20px 45px 40px 45px;
    } */
    .workout_modal h3.sub_heading2{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .workout_modal h3.sub_heading2 i{
        color: #999;
        margin-left: auto;
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }
    .workout_modal h3.sub_heading2.rotate i{
        transform: rotate(-180deg);
    }
    .workout_modal .workout_card{
        display: flex;
        max-height: 0;
        overflow: hidden;
        flex-direction: column;
        padding-bottom: 10px;
        transition: all 0.3s ease-in-out;
    }
    .workout_modal .workout_card.show{
        max-height: 100000px;
    }
    .workout_modal .workout_card li{
        display: flex;
        flex-direction: column;
    }
    .workout_modal .workout_card li a{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #2f7eed;
        /* margin: 0 0 0 auto; */
        cursor: pointer;
        flex-shrink: 0;
    }
    .workout_modal .workout_card li .category_title{
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        margin: 0 auto 7px 0;
    }
    .workout_modal .workout_card li .card_wrapper{
        border-radius: 6px;
        display: flex;
        /* flex-wrap: wrap; */
        gap: 15px;
        cursor: pointer;
        padding: 15px 0 0 0;
    }
    .workout_modal .workout_card li .card_wrapper svg{
        flex: 0 0 18px;
        height: 18px;
    }
    .workout_modal .workout_card li .video_area{
        width: 25%;
        border-right: 1px solid #f5f5f5;
    }
    .workout_modal .workout_card li .video_area img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .workout_modal .workout_card li .info_wpr{
        border-bottom: 1px solid #e9e9e9;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;  
        position: relative;
        padding-bottom: 15px;
        gap: 0;
        transition: all 0.3s ease-in-out;
    }
    .workout_modal .workout_card li:last-child .info_wpr{
        border-bottom: 0;
    }
    .workout_modal .workout_card li .info_wpr h4{
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-right: 20px;
        gap: 5px;
        justify-content: space-between;
        position: relative;
    }
    .workout_modal .workout_card li .info_wpr span.tag{
        background: #ecf4ff;
        padding: 0 6px;
        border-radius: 8px;
        font-size: 9px;
        line-height: 15px;
        color: #2f7eed;
        /* margin-left: 6px; */
    }
    .workout_modal .workout_card li .info_wpr h4 i{
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 10px;
        color: #999;
        transition: all 0.3s ease-in-out;
    }
    /* .workout_modal .workout_list li .info_wpr h4 i{
        margin-left: 15px;
    } */
    .workout_modal .workout_card li .info_wpr h6{
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        /* margin: 0 -5px 5px -5px; */
        gap: 5px;
    }
    .workout_modal .workout_card li .info_wpr h6 span{
        padding: 0 5px 0 0;
    }
    .workout_modal .workout_card li .info_wpr h6 span:not(:last-child){
        border-right: 1px solid #d9d9d9;
    }
    .workout_modal .workout_card li .info_wpr .blocks{
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-bottom: 10px;
        flex: 1 1 auto;
    }
    .workout_modal .workout_card li .info_wpr .blocks .block_title{
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
    }
    .workout_modal .workout_card li .info_wpr .blocks .block_title h6{
        font-weight: 400;
        margin-top: 5px;
    }
    .workout_modal .workout_card li .info_wpr .block_item{
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 5px;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
    }
    .workout_modal .workout_card li .info_wpr .block_item h5{
        font-size: 11px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
    }
    .workout_modal .workout_card li .info_wpr .block_item h6{
        font-size: 9px;
        line-height: 12px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .workout_modal .workout_card li .info_wpr .block_item h6 .v_tag {
        width: 14px;
        height: 14px;
        background: #999;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        line-height: 10px;
        font-weight: 500;
        color: #fff;
        margin-right: 5px;
        border-radius: 50%;
        padding: 0;
    }
    table.task_history{
        width: 100%;
        border-spacing: 8px;
        background: #f5f5f5;
        border-radius: 5px;
        padding: 0 5px 5px 5px;
    }
    .task_history th, .task_history td {
        padding: 10px 0;
        border-radius: 3px;
        text-align: center;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
    }
    .task_history td  {
        width: 23%;
        background: #fff;
        color: #777;
    }
    .workout_modal .workout_card li .info_wpr .todo_list{
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 10px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li{
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 10px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li label {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #d9d9d9;
        color: #777;
        font-size: 9px;
        line-height: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin-top: 2px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans{
        display: flex;
        flex-direction: column;
        gap: 7px;
        align-items: flex-start;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .yes_no_box{
        display: flex;
        gap: 7px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .yes_no_box.disabled{
        opacity: 0.5;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .yes_box{
        width: 60px;
        border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        border-radius: 4px;
        padding: 5px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 7px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .yes_box i{
        font-size: 10px;
        margin-top: 1px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .no_box{
        width: 60px;
        border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        border-radius: 4px;
        padding: 5px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 7px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .no_box i{
        font-size: 10px;
        margin-top: 3px;
    }
    .workout_modal .workout_card li .info_wpr .todo_list li .task_ans .ans_text{
        min-width: 50px;
        background: #f5f5f5;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .workout_modal .workout_card li .info_wpr .details{
        display: flex;
        flex-wrap: wrap;
        max-height: 0;
        overflow: hidden;
        gap: 15px;
        transition: all 0.3s ease-in-out;
    }
    .workout_modal .workout_card li .info_wpr .details .redirect_btn{
        font-size: 11px;
        line-height: 14px;
        padding: 6px 12px;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
        margin: 0 0 0 auto;
        text-decoration: none;
    }
    .workout_modal .workout_card li .info_wpr .details .details_info{
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
        gap: 7px;
    }
    .workout_modal .workout_card li.show .info_wpr{
        gap: 15px;
    }
    .workout_modal .workout_card li.show .info_wpr h4 i{
        transform: rotate(-180deg);
    }
    .workout_modal .workout_card li.show .info_wpr .details{
        max-height: 100000px;
    }
    /* .booking_details{
        background: #fff;
        border-radius: 0 0 8px 8px;
        padding: 20px 20px 30px 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 25px;
    } */
    .booking_details .group_details{
        width: 100%;
        display: flex;
        /* flex-wrap: wrap; */
        gap: 15px;
    }
    .booking_details .item_details{
        flex: 0 1 50%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .booking_details h5 {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .workout_modal .workout_card li .booking_details h6 {
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #777;
    }
    .booking_details p{
        font-size: 11px;
        line-height: 18px;
        font-weight: 400;
        color: #888;
    }
    .workout_modal .workout_card li .booking_details a.link{
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #2f7eed;
        text-decoration: none;
        word-break: break-all;
        margin: 0;
        padding-left: 5px;
    }
    .additional_info{
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .location_details{
        max-width: 370px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        column-gap: 15px;
    }
    .location_fld{
        flex: 0 1 300px;
        border: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 20px;
        position: relative;
        padding: 0 40px 0 0;
    }
    .location_fld input{
        width: 100%;
        height: 35px;
        padding: 0 0 0 15px;
        font-size: 11px;
        color: #5a5a5a;
        font-weight: 400;
        background: transparent;
    }
    .location_fld .suffix{
        position: absolute;
        right: 0;
        top: 0;
        border-left: 1px solid #e9e9e9;
        width: 40px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .location_fld .suffix img{
        width: 16px;
        height: auto;
    }
    .booking_details label.checkbox_opt{
        flex: 0 0 65px;
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        gap: 10px;
    }
    .booking_details label.checkbox_opt .check_box{
        width: 16px;
        height: 16px;
        border: 1px solid #999;
        background: #fff;
        border-radius: 2px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }
    .booking_details label.checkbox_opt .check_box i{
        font-size: 10px;
        color: #121525;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .booking_details label.checkbox_opt .check_box.active i{
        transform: scale(1);
    }
    .booking_details label.checkbox_opt h5{
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
    }
    .booking_details .action_btns{
        padding: 10px 0;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
    }
    .workout_modal .workout_card .booking_details .action_btns a{
        font-size: 11px;
        line-height: 14px;
        padding: 6px 12px;
        border: 1px solid #e9e9e9;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
        margin: 0;
    }
    .workout_modal .workout_card .booking_details .action_btns a i{
        font-size: 9px;
    }
    .booking_details .action_btns a.delete_btn{
        color: #eb1414;
    }
    /* .info_wpr .score_circle {
        position: absolute;
        right: 15px;
        top: 15px;
    } */
    .info_wpr .score_circle .inner_circle{
        background: #fff;
    }
    .info_wpr .score_circle .inner_circle .score {
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
    }

    .event_calendar{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .event_calendar :deep(.fullCalendar){
        width: 100%;
        margin: 25px 0 50px 0;
    }
    .event_calendar :deep(.fc-header-toolbar){
        justify-content: flex-start;
    }
    .event_calendar :deep(.fc-header-toolbar .fc-button){
        background: transparent;
        height: 27px;
        padding: 5px 10px;
        color: #121525;
        font-size: 11px;
        line-height: 1;
        border-radius: 3px;
        border: 1px solid #dbdbdb;
        outline: 0;
        box-shadow: none !important;
        text-transform: capitalize;
        margin-left: 3px;
    }
    .event_calendar :deep(.fc-header-toolbar .fc-button.fc-complete-button){
        margin-left: 10px;
    }
    .event_calendar :deep(.fc-header-toolbar .fc-button.fc-button-active){
        background: #dbdbdb;
    }
    /* .event_calendar :deep(.fc-header-toolbar .fc-button.fc-complete-button.active), .event_calendar :deep(.fc-header-toolbar .fc-button.fc-incomplete-button.active){
        background: #dbdbdb;
    }
    .event_calendar :deep(.fc-header-toolbar .fc-button.fc-button-active){
        background: #dbdbdb;
    } */
    .event_calendar :deep(.fc-header-toolbar .fc-button.fc-prev-button), .event_calendar :deep(.fc-header-toolbar .fc-button.fc-next-button){
        border-radius: 50%;
        padding: 0;
        width: 25px;
        height: 25px;
        margin-right: 7px;
        background: #dbdbdb;
        color: #121525;
        font-size: 13px;
        line-height: normal;
        border: 0;
    }
    .event_calendar :deep(.fc .fc-toolbar-title){
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        color: #121525;
        margin-left: 10px;
    }
    .event_calendar :deep(.fc-header-toolbar .fc-toolbar-chunk:last-of-type){
        margin-left: auto;
    }
    .event_calendar :deep(.fc-dayGridMonth-view), .event_calendar :deep(.fc-dayGridWeek-view){
        overflow-x: auto;
    }
    .event_calendar :deep(table.fc-scrollgrid){
        border-radius: 10px;
        width: 100%;
    }
    .event_calendar :deep(.fc-dayGridMonth-view table.fc-scrollgrid), .event_calendar :deep(.fc-dayGridWeek-view table.fc-scrollgrid){
        min-width: 850px;
    }
    .event_calendar :deep(table.fc-col-header), .event_calendar :deep(.fc-daygrid-body), .event_calendar :deep(table.fc-scrollgrid-sync-table){
        width: 100% !important;
    }
    .event_calendar :deep(.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > th:last-child){
        border-radius: 0 10px 0 0;
        background: transparent;
    }
    .event_calendar :deep(.fc-scrollgrid-section-body > td){
        border-radius: 0 0 10px 10px;
    }
    .event_calendar :deep(.fc-view .fc-col-header-cell){
        padding: 10px 5px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        background: #fff;
        position: relative;
    }
    .event_calendar :deep(.fc-view .fc-col-header-cell:after){
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.1;
        background: var(--var-portal-tab-background-color);
    }
    .event_calendar :deep(.fc-view .fc-col-header-cell:first-child), .event_calendar :deep(.fc-view .fc-col-header-cell:first-child:after){
        border-top-left-radius: 10px;
    }
    .event_calendar :deep(.fc-view .fc-col-header-cell:last-child), .event_calendar :deep(.fc-view .fc-col-header-cell:last-child:after){
        border-top-right-radius: 10px;
    }
    .event_calendar :deep(.fc-view tbody tr:last-child td:first-child){
        border-bottom-left-radius: 10px;
    }
    .event_calendar :deep(.fc-view tbody tr:last-child td:last-child){
        border-bottom-right-radius: 10px;
    }
    .event_calendar :deep(.fc-theme-standard td.fc-daygrid-day){
        height: 120px;
    }
    .event_calendar :deep(.fc .fc-daygrid-day.fc-day-today){
        position: relative;
        background: #fff;
    }
    .event_calendar :deep(.fc .fc-daygrid-day.fc-day-today:before){
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.05;
        background: var(--var-portal-tab-background-color);
    }
    .event_calendar :deep(.fc .fc-daygrid-day.fc-day-future){
        background: #fff;
    }
    .event_calendar :deep(.fc .fc-day-other.fc-day-future){
        background: #fbfbfb;
    }
    .event_calendar :deep(.fc .fc-event){
        background: transparent;
        z-index: auto;
        border: 0;
    }
    .event_calendar :deep(.fc-daygrid-day-events){
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .event_calendar :deep(.fc-event .fc-event-main){
        display: flex;
        padding: 2px;
        position: static;
        white-space: normal;
    }
    .event_calendar :deep(.fc-daygrid-day-events label), .event_calendar :deep(.fc-popover .fc-popover-body .fc-event label){
        font-size: 11px;
        line-height: 14px;
        background: #ffe8c1;
        font-weight: 500;
        color: #121525;
        padding: 4px 15px;
        border-radius: 11px;
        position: relative;
        cursor: pointer;
    }
    .event_calendar :deep(.fc-daygrid-day-events label.event), .event_calendar :deep(.fc-popover .fc-popover-body .fc-event label.event){
        background: #dae9ff;
    }
    .event_calendar :deep(.fc-daygrid-day-events label.workout), .event_calendar :deep(.fc-popover .fc-popover-body .fc-event label.workout){
        padding: 4px 40px 4px 10px;
        background: #f1f1f1;
    }
    .event_calendar :deep(.fc-day-today .fc-daygrid-day-events label.workout){
        background: #fff;
    }
    .event_calendar :deep(.fc-daygrid-day-events label span), .event_calendar :deep(.fc-popover .fc-popover-body .fc-event label span){
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        background: #dbdbdb;
        color: #121525;
        padding: 3px 8px;
        font-size: 9px;
        line-height: 12px;
        border-radius: 0 12px 12px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .event_calendar :deep(.fc .fc-daygrid-more-link){
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        background: transparent;
        margin-top: 5px;
    }
    .event_calendar :deep(.fc-theme-standard .fc-popover){
        z-index: 9;
    }
    .event_calendar :deep(.fc .fc-more-popover .fc-popover-body){
        min-width: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 10px;
    }
    .event_calendar :deep(.fc-theme-standard .fc-popover-header){
        padding: 10px;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #121525;
        background: #fff;
        border-bottom: 1px solid #e9e9e9;
    }
    .event_calendar :deep(.fc-theme-standard .fc-popover-header .fc-icon-x:before){
        font-weight: 600;
    }

    .event_calendar :deep(.quick_info){
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        width: auto;
        min-width: 180px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 100%;
        bottom: auto;
        transform: translateX(-50%);
        display: none;
        margin-top: 5px;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
        z-index: 10;
    }
    .event_calendar :deep(.quick_info:after) {
        content: "";
        position: absolute;
        bottom: 100%;
        top: -6px;
        left: calc(50% - 5px);
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(-135deg);
        z-index: 2;
    }
    .event_calendar :deep(.fc .fc-event:hover .quick_info){
        display: flex;
        flex-direction: column;
        gap: 5px;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .event_calendar :deep(.fc .fc-event:hover .quick_info span){
        font-size: 10px;
        line-height: 14px;
        color: #121525;
        font-weight: 600;
    }
    .event_calendar :deep(.fc .fc-event:hover .quick_info i.success){
        color: #28a745;
        margin-left: 5px;
    }
    .event_calendar :deep(.fc .fc-event:hover .quick_info i.danger){
        color: #eb1414 ;
        margin-left: 5px;
    }
    .event_calendar :deep(.quick_info .event_info){
        display: flex;
        align-items: flex-start;
        gap: 5px;
        margin-bottom: 3px;
    }
    .event_calendar :deep(.quick_info .event_info span){
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        border-radius: 50%;
        font-size: 8px;
        line-height: 14px;
        color: #121525;
        font-weight: 500 !important;
        text-align: center;
        background: #e9e9e9;
    }
    .event_calendar :deep(.quick_info h5){
        flex: 1 1 auto;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        text-align: left;
    }
    .event_calendar :deep(.quick_info h6){
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .event_calendar :deep(.quick_info .tag){
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
        background: #e9e9e9;
        padding: 3px 10px;
        border-radius: 10px;
        color: #121525;
        margin-top: 3px;
    }

    /* week view */
    .event_calendar :deep(.fc-dayGridWeek-view .fc-daygrid-day-events){
        align-items: flex-start;
        padding: 10px 3px;
        gap: 10px;
    }
    .event_calendar :deep(.fc-dayGridWeek-view .activity_list){
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
    }
    .event_calendar :deep(.fc-dayGridWeek-view .activity_list .list_item){ 
        padding: 5px 0 5px 20px;
        position: relative;
    }
    .event_calendar :deep(.fc-dayGridWeek-view .activity_list .list_item svg){
        width: 14px;
        height: 14px;
        position: absolute;
        left: 0;
        top: 6px;
    }

    /* day view */
    .event_calendar :deep(.fc-dayGridDay-view .fc-daygrid-day-events){
        align-items: flex-start;
        padding: 10px 3px;
        gap: 10px;
    }
    .event_calendar :deep(.fc-dayGridDay-view .fc-daygrid-day-events){
        max-width: 500px;
        margin: 0 auto;
    }
    .event_calendar :deep(.fc-dayGridDay-view .fc-event .fc-event-main){
        flex-direction: column;
    }
    .event_calendar :deep(.fc-dayGridDay-view .activity_list){
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
    }
    .event_calendar :deep(.fc-dayGridDay-view .activity_list .list_item){ 
        padding: 10px 0 10px 30px;
        position: relative;
    }
    .event_calendar :deep(.fc-dayGridDay-view .activity_list .list_item svg){
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 8px;
    }
    @keyframes smoothMove {
        0% {
            top: 30%;
            opacity: 0;
        }

        10% {
            top: 30%;
            opacity: 0.5;
        }

        100% {
            top: 100%;
            opacity: 1;
        }
    }
    .workout_modal .view_plan{
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 100vh;
        background: #fff;
        z-index: 1;
        transition: all 0.5s ease-in-out;
        overflow-y: scroll;
    }
    .workout_modal .view_plan::-webkit-scrollbar{
        display: none;
    }
    .workout_modal .view_plan.show{
        top: 0;
    }
    .workout_modal .view_plan .close_btn{
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        padding: 0 15px;
        background: #2f7fed;
        border-radius: 0 0 6px 6px;
        font-size: 10px;
        color: #fff;
        cursor: pointer;
        width: auto;
        height: 12px;
    }
    .workout_modal .view_plan .header{
        border-bottom: 1px solid #e9e9e9;
        background: #fff;
        z-index: 11;
        position: sticky;
        top: 0;
        padding: 30px 0 15px 0;
    }
    .workout_modal .view_plan h2{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        color: #121525;
    }
    .workout_modal .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .workout_modal .cell:after,
    .workout_modal .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .workout_modal .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .workout_modal .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .workout_modal .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }
    .workout_modal .cell .content_area::-webkit-scrollbar{
        display: none;
    }

    .result_wpr.new{
        overflow: visible;
    }
    .portal_forms{
        margin-bottom: 50px;
    }
    .inner_pages .actions .filter_btn {
        padding: 0;
        display: flex;
        gap: 30px;
    }

    .inner_pages .actions .filter_btn .btn_item {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        position: relative;
        transition: all 0.3s ease-in-out;
    }

    .inner_pages .actions .filter_btn .btn_item.active {
        color: #121525;
        font-weight: 500;
    }
    @media(max-width: 1250px){
        .sliderWpr .client_productList li {
            flex: 0 0 33.333%;
        }
        .event_calendar :deep(.fc-theme-standard td.fc-daygrid-day){
            height: 100px;
        }
    }
    @media(max-width: 767px){
        .dashboard_content .right_side{
            margin: 0;
        }
        .sliderWpr{
            max-width: 600px;
            margin: 0 auto;
        }
        .sliderWpr .client_productList li {
            flex: 0 0 50%;
        }
        /* .workout_list{
            padding: 20px 30px;
            margin: 0 -30px;
        } */
        .workout_modal .workout_card li .info_wpr h4{
            flex-direction: column;
            align-items: flex-start;
        }
        /* .workout_modal .workout_card li .info_wpr .block_item{
            flex-wrap: wrap;
        } */
        .workout_modal .workout_card li .info_wpr .details .details_info{
            padding: 0 15px 15px 15px;
        }
        /* .workout_modal .workout_card li .card_wrapper{
            gap: 0;
        } */
        .workout_modal .workout_card li .video_area{
            width: 100%;
        }
        .workout_modal .workout_card li .video_area iframe {
            border-radius: 6px 6px 0 0;
        }
        /* .workout_modal .workout_card li .info_wpr{
            width: 100%;
            padding: 15px;
        } */
    }
    @media(max-width: 650px){
        .event_calendar :deep(.fc-header-toolbar){
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
        }
        .event_calendar :deep(.fc-header-toolbar .fc-toolbar-chunk:last-of-type){
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
    @media(max-width: 499px){
        .dashboard_content .section_item{
            padding: 20px 0;
        }
        .logo_img{
            max-height: 25px;
        }
        .client_card .card_header{
            padding: 15px;
        }
        .client_card .card_header .dashboard-menu-text h4{
            font-size: 9px;
            line-height:11px !important;
            padding: 4px 10px;
        }
        .client_card .card_header .user_box img{
            width: 20px;
            height:20px;
        }
        .client_card .card_header .user_box h5{
            font-size: 11px;
            line-height: 14px;
        }
        .sliderWpr{
            max-width: 280px;
            margin: 0 auto;
        }
        .sliderWpr .client_productList li {
            flex: 0 0 100%;
        }
        .event_calendar :deep(.fc-theme-standard td.fc-daygrid-day){
            height: 80px;
        }
        .event_calendar :deep(.fc .fc-toolbar-title){
            font-size: 15px;
            line-height: 20px;
            margin-left: 5px;
        }
        .event_calendar :deep(.fc-header-toolbar .fc-button.fc-complete-button), .event_calendar :deep(.fc-header-toolbar .fc-button.fc-incomplete-button){
            height: 25px;
            padding: 5px 10px;
            font-size: 11px;
        }
        .event_calendar :deep(.fc-header-toolbar .fc-button.fc-prev-button), .event_calendar :deep(.fc-header-toolbar .fc-button.fc-next-button){
            width: 20px;
            height: 20px;
            margin-right: 7px;
            font-size: 11px;
        }
    }
</style>
